import { useColorModeValue } from '@chakra-ui/react';
import { StylesOptions } from 'react-joyride';

export default function useJoyrideStylesOptions() {
  return useColorModeValue<Partial<StylesOptions>, Partial<StylesOptions>>({
    arrowColor: 'var(--chakra-colors-white)',
    backgroundColor: 'var(--chakra-colors-white)',
    overlayColor: 'var(--chakra-colors-blackAlpha-800)',
    primaryColor: 'var(--chakra-colors-deepPurple-500)',
    textColor: 'var(--chakra-colors-chakra-body-text)',
  }, {
    arrowColor: 'var(--chakra-colors-gray-900)',
    backgroundColor: 'var(--chakra-colors-gray-900)',
    overlayColor: 'var(--chakra-colors-blackAlpha-800)',
    primaryColor: 'var(--chakra-colors-deepPurple-200)',
    textColor: 'var(--chakra-colors-chakra-body-text)',
  });
}
