import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuGift } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';

import Gender from '../../common/Gender';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';

export default function WishlistButton() {
  const { t } = useTranslation('MainLayout');

  const wishlistMatch = useMatch('/wishlist/*');

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  if (myProfileDoc.gender === Gender.MALE) {
    return null;
  }

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('wishlistButton.default')}
        as={Link}
        className="wishlistButton"
        icon={<Icon as={LuGift} boxSize={6} />}
        isActive={!!wishlistMatch}
        isRound
        size="lg"
        to="/wishlist"
        variant="ghost"
      />
    </VStack>
  );
}
