import {
  Box,
  BoxProps,
  HStack,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Gender from '../../../common/Gender';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';
import useStripeCreateCheckoutSession from '../../../functions/useStripeCreateCheckoutSession';

export type Props = BoxProps;

enum CardType {
  SUBSCRIBE,
  UPGRADE,
  CURRENT,
  DOWNGRADE,
}

export default function DionysusButton(boxProps: Props) {
  const { t } = useTranslation('MyProfileScreen', { keyPrefix: 'Subscription' });

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const cardType = useMemo<CardType>(() => {
    switch (myProfileDoc.tier) {
      case Tier.HERMES:
        return CardType.UPGRADE;

      case Tier.DIONYSUS:
        return CardType.CURRENT;

      case Tier.POSEIDON:
      case Tier.ZEUS:
        return CardType.DOWNGRADE;

      default:
        return CardType.SUBSCRIBE;
    }
  }, [myProfileDoc]);

  const [url, setUrl] = useState<null | string>();

  const stripeCreateCheckoutSession = useStripeCreateCheckoutSession();

  useEffect(
    () => {
      if (myProfileDoc.gender === Gender.MALE) {
        stripeCreateCheckoutSession({
          cancelUrl: 'https://app.qupidu.com/profile',
          successUrl: 'https://app.qupidu.com/profile',
          tier: Tier.DIONYSUS,
        })
          .then(({ data }) => setUrl(data))
          .catch(() => { });
      }
    },
    [stripeCreateCheckoutSession, myProfileDoc.gender],
  );

  if (cardType === CardType.DOWNGRADE) {
    return null;
  }

  if (myProfileDoc?.gender !== Gender.MALE) {
    return null;
  }

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      _dark={{
        backgroundColor: `rgb(from var(--chakra-colors-${TierMap[Tier.DIONYSUS].colorScheme}-200) r g b / 0.16)`,
        color: `var(--chakra-colors-${TierMap[Tier.DIONYSUS].colorScheme}-200)`,
      }}
      _light={{
        backgroundColor: `var(--chakra-colors-${TierMap[Tier.DIONYSUS].colorScheme}-100)`,
        color: `var(--chakra-colors-${TierMap[Tier.DIONYSUS].colorScheme}-800)`,
      }}
      as={url && cardType !== CardType.CURRENT ? 'a' : undefined}
      borderRadius="md"
      href={url && cardType !== CardType.CURRENT ? url : undefined}
      px={5}
      py={3}
      userSelect="none"
    >
      <VStack alignItems="stretch" height="100%">
        <HStack>
          <Icon as={TierMap[Tier.DIONYSUS].icon} boxSize={6} />

          <Text flex={1} fontSize="xl" fontWeight="bold">
            {t('dionysusButton.title')}
          </Text>

          <Text fontSize="xl" fontWeight="bold">
            {cardType === CardType.CURRENT ? t('active') : '€50'}
          </Text>
        </HStack>

        <Text fontSize="xs">
          {t('dionysusButton.body')}
        </Text>

        <UnorderedList fontSize="xs">
          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('badge')}
              :
            </Text>
            {' '}
            {t('dionysusButton.badge')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('baseScore')}
              :
            </Text>
            {' '}
            {t('dionysusButton.baseScore')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('tripBudget')}
              :
            </Text>
            {' '}
            {t('dionysusButton.tripBudget')}
          </ListItem>
        </UnorderedList>
      </VStack>
    </Box>
  );
}
