import {
  Center,
  Container,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function EventsScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'EventsScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('EventsScreen');

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VStack alignItems="stretch" spacing={4}>
          <Text fontSize="2xl" fontWeight="bold">
            {t('announcement.heading')}
          </Text>

          <Text>
            {t('announcement.text')}
          </Text>

          <Text>
            {t('announcement.text2')}
          </Text>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option1.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1672939113905-49237296683a?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option1.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option2.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1505236858219-8359eb29e329?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option2.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option3.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1491510736257-3ad769ff47b6?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option3.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option4.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1666052974990-8f89aac6f673?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option4.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option5.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1498594720837-511a63115921?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option5.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option6.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1526728388798-765ee8e1a5aa?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option6.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option7.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1516091877740-fde016699f2c?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option7.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option8.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1619157267030-1e13d0d0a23e?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option8.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option9.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1543728069-a3f97c5a2f32?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option9.text')}
            </Text>
          </VStack>

          <VStack alignItems="stretch" spacing={2}>
            <Text fontSize="lg" fontWeight="bold">
              {t('announcement.options.option10.title')}
            </Text>
            <Image
              src="https://images.unsplash.com/photo-1524014482380-0988169f598d?w=1500&ar=16:9&fm=webp&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <Text>
              {t('announcement.options.option10.text')}
            </Text>
          </VStack>

          <Text>
            {t('announcement.text3')}
          </Text>
        </VStack>
      </Container>
    </VStack>
  );
}

export default function EventsScreen() {
  const { t } = useTranslation('EventsScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <EventsScreenMain />
      </Suspense>
    </Catch>
  );
}
