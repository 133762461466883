import { Icon, IconProps } from '@chakra-ui/react';

import Tier from '../common/Tier';
import TierMap from '../common/TierMap';

export type Props = {
  tier: Tier;
} & IconProps;

export default function TierIcon({
  tier,
  ...iconProps
}: Props) {
  if (tier === Tier.MORTAL) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.MORTAL].icon} color={`${TierMap[Tier.MORTAL].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.HERMES) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.HERMES].icon} color={`${TierMap[Tier.HERMES].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.DIONYSUS) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.DIONYSUS].icon} color={`${TierMap[Tier.DIONYSUS].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.POSEIDON) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.POSEIDON].icon} color={`${TierMap[Tier.POSEIDON].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.ZEUS) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.ZEUS].icon} color={`${TierMap[Tier.ZEUS].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.ARTEMIS) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.ARTEMIS].icon} color={`${TierMap[Tier.ARTEMIS].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.ATHENA) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.ATHENA].icon} color={`${TierMap[Tier.ATHENA].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.APHRODITE) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.APHRODITE].icon} color={`${TierMap[Tier.APHRODITE].colorScheme}.200`} {...iconProps} />
    );
  }

  if (tier === Tier.HERA) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Icon as={TierMap[Tier.HERA].icon} color={`${TierMap[Tier.HERA].colorScheme}.200`} {...iconProps} />
    );
  }

  return null;
}
