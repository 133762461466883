import {
  Box,
  Center,
  Container,
  Text,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { orderBy, query, where } from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import ApplicationSnapProvider from '../../components/snapProviders/ApplicationSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Application from './Application';

export type Props = {
  height: number;
  width: number;
};

export function ApplicationListMain({ height, width }: Props) {
  const { t } = useTranslation('ApplicationsScreen');
  const myProfileSnap = useMyProfileSnap();

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      orderBy('sentAt', 'desc'),
    ),
  );

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(applicationsSnap.docs.length, (i) => ({
    borderRadius: 0,
    display: 'block',
    scale: 1,
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        applicationsSnap.docs.length - 1,
      );
      setCurrentIndex(animationIndex.current);
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          display: 'none',
        };
      }

      const borderRadius = active
        ? (clamp(
          Math.abs(mx),
          0,
          100,
        ) / 100) * 32
        : 0;
      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return {
        borderRadius, display: 'block', scale, x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 10,
  });

  useEffect(
    () => {
      if (applicationsSnap.docs[currentIndex]) {
        const applicationSnap = applicationsSnap.docs[currentIndex];
        const applicationDoc = applicationSnap.data();

        mixpanel.track('Applications List Scrolled', {
          acceptedAt: applicationDoc.acceptedAt?.toDate(),
          applicantId: applicationDoc.applicantRef.id,
          applicationId: applicationSnap.id,
          index: currentIndex,
          organizerId: applicationDoc.organizerRef.id,
          rejectedAt: applicationDoc.rejectedAt?.toDate(),
          rejectedBy: applicationDoc.rejectedBy,
          sentAt: applicationDoc.sentAt.toDate(),
          status: applicationDoc.status,
          tripId: applicationDoc.tripRef?.id,
        });
      }
    },
    [currentIndex, applicationsSnap.docs],
  );

  if (!applicationsSnap.docs.length) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <Text textAlign="center">
            {t('emptyList.body')}
          </Text>
        </Center>
      </Container>
    );
  }

  return (
    <Box
      h={`${height}px`}
      overflow="hidden"
      position="relative"
      w={`${width}px`}
    >
      {pages.map(({
        borderRadius, display, scale, x,
      }, searchResultPosition) => (
        Math.abs(currentIndex - searchResultPosition) > 5
          ? null
          : (
            <Box
              as={animated.div}
              h={`${height}px`}
              key={applicationsSnap.docs[searchResultPosition].id}
              position="absolute"
              w={`${width}px`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              style={{
                display,
                touchAction: 'none',
                x,
              }}
            >
              <ApplicationSnapProvider
                applicationSnap={applicationsSnap.docs[searchResultPosition]}
              >
                <Application
                  as={animated.div}
                  height={height}
                  overflow="hidden"
                  style={{
                    borderRadius: borderRadius as unknown as number,
                    scale: scale as unknown as number,
                    touchAction: 'none',
                  }}
                  width={width}
                />
              </ApplicationSnapProvider>
            </Box>
          )
      ))}
    </Box>
  );
}

export default function ApplicationList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ApplicationListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
