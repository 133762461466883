import { Box, BoxProps, VStack } from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { clamp } from 'lodash';
import { Suspense, useRef } from 'react';

import TripAlgoliaSearchRecord from '../../../../common/TripAlgoliaSearchRecord';
import Catch from '../../../../components/Catch';
import { useInsets } from '../../../../components/InsetsProvider';
import StoragePictureCell from '../../../../components/StoragePictureCell';
import UnsplashPictureCell from '../../../../components/UnsplashPictureCell';
import InfoCell from './InfoCell';

export type Props = {
  height: number;
  queryId: string | undefined;
  searchResultPosition: number;
  tripRecord: TripAlgoliaSearchRecord;
  width: number;
} & BoxProps;

export function TripMain({
  height,
  queryId,
  searchResultPosition,
  tripRecord,
  width,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const ref = useRef<HTMLDivElement>(null);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(tripRecord.organizer.pictures.length + 1, (i) => ({
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: `rgb(from var(--chakra-colors-white) r g b / ${i === 0 ? 1 : (i === 1 ? 0.5 : 0.25)})`,
    display: 'block',
    y: i * height,
  }));

  const bind = useDrag(({
    active, cancel, direction: [,yDir], movement: [,my],
  }) => {
    if (active && Math.abs(my) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (yDir > 0 ? -1 : 1),
        0,
        tripRecord.organizer.pictures.length,
      );
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.25)',
          display: 'none',
        };
      }

      const y = (i - animationIndex.current) * height + (active ? my : 0);
      const backgroundColor = `rgb(from var(--chakra-colors-white) r g b / ${i !== animationIndex.current ? 0.5 : 1})`;
      return {
        backgroundColor,
        display: 'block',
        y,
      };
    });
  }, {
    axis: 'y',
    preventDefault: true,
    threshold: 10,
  });

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      className="trip"
      h={`${height}px`}
      position="relative"
      w={`${width}px`}
    >
      <VStack
        gap={4}
        position="absolute"
        right={4}
        top={`calc(${insets.top} + var(--chakra-space-4))`}
        w={10}
        zIndex={10}
      >
        {pages.map(({ backgroundColor }, i) => (
          <Box
            as={animated.div}
            backdropFilter="saturate(180%) blur(20px)"
            borderRadius="full"
            h={2}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{ backgroundColor }}
            transition="background-color 0.15s ease-in-out"
            w={2}
          />
        ))}
      </VStack>

      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        ref={ref}
        w={`${width}px`}
      >
        {pages.map(({ display, y }, i) => {
          if (i === 0) {
            return (
              <UnsplashPictureCell
                as={animated.div}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                height={height}
                key={tripRecord.picture.urls.raw}
                picture={tripRecord.picture}
                position="absolute"
                style={{
                  display: display as unknown as string,
                  touchAction: 'none',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  y,
                }}
                width={width}
              />
            );
          }

          return (
            <StoragePictureCell
              as={animated.div}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              height={height}
              key={tripRecord.organizer.pictures[i - 1].imgixUrl}
              picture={tripRecord.organizer.pictures[i - 1]}
              position="absolute"
              style={{
                display: display as unknown as string,
                touchAction: 'none',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                y,
              }}
              width={width}
            />
          );
        })}
      </Box>

      <InfoCell
        left={0}
        position="absolute"
        queryId={queryId}
        right={0}
        searchResultPosition={searchResultPosition}
        style={{
          bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
        }}
        tripRecord={tripRecord}
        zIndex={20}
      />
    </Box>
  );
}

export default function Trip(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
