/* eslint-disable no-console */
import {
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Skeleton,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import {
  AuthErrorCodes,
  linkWithCredential,
  PhoneAuthProvider,
  signInWithCredential,
  User,
} from 'firebase/auth';
import { Formik, FormikHelpers } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuArrowLeft, LuArrowRight } from 'react-icons/lu';
import { useAuth } from 'reactfire';
import * as yup from 'yup';

import Catch from '../../../components/Catch';
import useShowError from '../../../hooks/useShowError';

export type Props = {
  onCancel: () => void;
  onComplete: (values: { user: User }) => void;
  userExists: boolean;
  verificationId: string;
};

export function VerificationCodeFormMain({
  onCancel,
  onComplete,
  userExists,
  verificationId,
}: Props) {
  const { t } = useTranslation('MainLayout', { keyPrefix: 'Onboarding.Login.VerificationCodeForm' });

  const schema = useMemo(
    () => yup.object().shape({
      verificationCode: yup
        .string()
        .length(6)
        .label(t('verificationCode.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      verificationCode: '',
    }),
    [],
  );

  const showError = useShowError();

  const auth = useAuth();

  const handleCancel = useCallback(
    () => {
      mixpanel.track('Onboarding Verification Code Step Canceled');
      onCancel();
    },
    [onCancel],
  );

  const handleConfirmCode = useCallback(
    async (
      values: typeof schema['__outputType'],
      formikHelpers: FormikHelpers<typeof schema['__outputType']>,
    ) => {
      if (!auth.currentUser) {
        return;
      }

      const phoneAuthCredential = PhoneAuthProvider.credential(
        verificationId,
        values.verificationCode,
      );

      try {
        const userCredential = userExists
          ? await signInWithCredential(auth, phoneAuthCredential)
          : await linkWithCredential(auth.currentUser, phoneAuthCredential);

        mixpanel.track('Onboarding Verification Code Step Completed', { userId: userCredential.user.uid });
        mixpanel.identify(userCredential.user.uid);

        onComplete({ user: userCredential.user });
      } catch (err) {
        console.log({ err });
        if (err instanceof FirebaseError) {
          if (err.code === AuthErrorCodes.CODE_EXPIRED) {
            formikHelpers.setFieldError('phoneNumber', 'Code expired.');
            return;
          }

          formikHelpers.setFieldError('verificationCode', err.message);
          return;
        }
        showError(err);
      }
    },
    [auth, onComplete, showError, userExists, verificationId],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik<typeof schema['__outputType']>
      initialValues={initialValues}
      onSubmit={handleConfirmCode}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <HStack
          as="form"
          gap={2}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={0}
        >
          <IconButton
            aria-label={t('backButton.default')}
            icon={<Icon as={LuArrowLeft} />}
            onClick={handleCancel}
            size="sm"
          />

          <FormControl isInvalid={!!errors.verificationCode}>
            <Input
              autoComplete="one-time-code"
              autoFocus
              inputMode="numeric"
              onChange={handleChange('verificationCode')}
              pattern="\d{6}"
              placeholder={t('verificationCode.label')}
              required
              size="sm"
              tabIndex={-1}
              type="text"
              value={values.verificationCode}
            />
          </FormControl>

          <IconButton
            aria-label={t('confirmCodeButton.default')}
            flex={0}
            icon={<Icon as={LuArrowRight} />}
            isLoading={isSubmitting}
            size="sm"
            type="submit"
          />
        </HStack>
      )}
    </Formik>
  );
}

export function VerificationCodeFormLoading() {
  return (
    <HStack>
      <Skeleton h={8} w={8} />
      <Skeleton flex={1} h={8} />
      <Skeleton h={8} w={8} />
    </HStack>
  );
}

export default function VerificationCodeForm(props: Props) {
  return (
    <Catch fallback={<VerificationCodeFormLoading />}>
      <Suspense fallback={<VerificationCodeFormLoading />}>
        <VerificationCodeFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
