import {
  Box,
  Center,
  Container,
  HStack,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useProfilesCollectionRef } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import ProfileSnapProvider from '../../components/snapProviders/ProfileSnapProvider';
import useDimensions from '../../hooks/useDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Profile from './Profile';

export function ProfileScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ProfileScreen' }); }, []);

  const { profileId } = useParams<{ profileId: string }>();

  const { t } = useTranslation('ProfileScreen');

  const insets = useInsets();

  const [ref, { height, width }] = useDimensions();

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );
  const { data: profileSnap } = useFirestoreDoc(profileRef);

  if (!profileSnap.exists()) {
    throw new Error('Profile not found');
  }

  return (
    <ProfileSnapProvider profileSnap={profileSnap}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <Box
        h="100%"
        position="relative"
      >
        <Box
          left={0}
          position="absolute"
          right={0}
          top={`max(${insets.top}, var(--chakra-space-2))`}
          zIndex={50}
        >
          <Container maxW="lg">
            <HStack gap={4}>
              <IconButton
                aria-label={t('backButton.default')}
                as={Link}
                icon={<Icon as={LuChevronLeft} />}
                to=".."
              />

              <LogoFull h="40px" w="120px" />
            </HStack>
          </Container>
        </Box>

        <InsetsProvider
          bottom={insets.bottom}
          left={insets.left}
          right={insets.right}
          top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
        >
          <Container h="100%" maxW="lg" px={0} ref={ref}>
            {height && width ? (
              <Profile height={height} width={width} />
            ) : null}
          </Container>
        </InsetsProvider>
      </Box>
    </ProfileSnapProvider>
  );
}

export default function ProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
