import {
  Badge,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMessageSquare } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';

export function ConversationsBadge() {
  const myProfileSnap = useMyProfileSnap();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: unreadConversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      where('status', '==', ConversationStatus.OPENED),
      where('notReadByRefs', 'array-contains', myProfileSnap.ref),
      limit(10),
    ),
  );

  const unreadConversationsCount = useMemo(
    () => unreadConversationsSnap.docs.length,
    [unreadConversationsSnap.docs.length],
  );

  if (!unreadConversationsCount) {
    return null;
  }

  return (
    <Badge colorScheme="red" mr={-5} mt={-5} variant="solid" zIndex={10}>
      {unreadConversationsCount > 9 ? '9+' : unreadConversationsCount}
    </Badge>
  );
}

export default function ChatsButton() {
  const { t } = useTranslation('MainLayout');

  const chatsMatch = useMatch('/chats/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('chatsButton.default')}
        as={Link}
        className="chatsButton"
        icon={<Icon as={LuMessageSquare} boxSize={6} />}
        isActive={!!chatsMatch}
        isRound
        size="lg"
        to="/chats"
        variant="ghost"
      />
      <Catch fallback={null}>
        <Suspense fallback={null}>
          <ConversationsBadge />
        </Suspense>
      </Catch>
    </VStack>
  );
}
