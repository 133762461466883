import {
  Box,
  BoxProps,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TripBudget from '../../../../common/TripBudget';
import TripBudgetMap from '../../../../common/TripBudgetMap';

export type Props = BoxProps;

export default function EconomyCard(props: Props) {
  const { t } = useTranslation('BudgetFormControl');

  return (
    <Box as="label" flex={1} userSelect="none">
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        _dark={{
          backgroundColor: `rgb(from var(--chakra-colors-${TripBudgetMap[TripBudget.ECONOMY].colorScheme}-200) r g b / 0.16)`,
          color: `var(--chakra-colors-${TripBudgetMap[TripBudget.ECONOMY].colorScheme}-200)`,
        }}
        _light={{
          backgroundColor: `var(--chakra-colors-${TripBudgetMap[TripBudget.ECONOMY].colorScheme}-100)`,
          color: `var(--chakra-colors-${TripBudgetMap[TripBudget.ECONOMY].colorScheme}-800)`,
        }}
        borderRadius="md"
        px={5}
        py={3}
      >
        <VStack alignItems="stretch" height="100%">
          <Text flex={1} fontSize="xl" fontWeight="bold">
            {t('economyCard.title')}
          </Text>

          <Text fontSize="sm">
            {t('economyCard.body')}
          </Text>

          <UnorderedList fontSize="xs">
            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('accommodations')}
                :
              </Text>
              {' '}
              {t('economyCard.accommodations')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('transportation')}
                :
              </Text>
              {' '}
              {t('economyCard.transportation')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('travel')}
                :
              </Text>
              {' '}
              {t('economyCard.travel')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('food')}
                :
              </Text>
              {' '}
              {t('economyCard.food')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('activities')}
                :
              </Text>
              {' '}
              {t('economyCard.activities')}
            </ListItem>
          </UnorderedList>
        </VStack>
      </Box>
    </Box>
  );
}
