import {
  Box,
  Center,
  Container,
  HStack,
  Icon,
  IconButton,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuFilter } from 'react-icons/lu';

import Catch from '../../../components/Catch';
import InsetsProvider, { useInsets } from '../../../components/InsetsProvider';
import LogoFull from '../../../components/LogoFull';
import LogoIcon from '../../../components/LogoIcon';
import useDimensions from '../../../hooks/useDimensions';
import ErrorFallbackScreen from '../../ErrorFallbackScreen';
import ProfileFiltersModal from './ProfileFiltersModal';
import ProfileList from './ProfileList';

export function ProfilesScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'ProfilesScreen' }); }, []);

  const insets = useInsets();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handlePopoverOpen = useCallback(() => {
    mixpanel.track('Profiles Filter Opened');
    onOpen();
  }, [onOpen]);

  const [ref, { height, width }] = useDimensions();

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        top={`max(${insets.top}, var(--chakra-space-2))`}
        zIndex={50}
      >
        <Container maxW="lg">
          <HStack alignItems="center" gap={4}>
            <LogoFull h="40px" w="120px" />

            <Spacer />

            <IconButton
              aria-label="Filter"
              className="filter"
              icon={<Icon as={LuFilter} />}
              onClick={handlePopoverOpen}
            />

            <ProfileFiltersModal isOpen={isOpen} onClose={onClose} />
          </HStack>
        </Container>
      </Box>

      <InsetsProvider
        bottom={insets.bottom}
        left={insets.left}
        right={insets.right}
        top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
      >
        <Container h="100%" maxW="lg" px={0} ref={ref}>
          {height && width ? (
            <ProfileList height={height} width={width} />
          ) : null}
        </Container>
      </InsetsProvider>
    </Box>
  );
}

export default function ProfilesScreen() {
  const { t } = useTranslation('ProfilesScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <ProfilesScreenMain />
      </Suspense>
    </Catch>
  );
}
