import {
  Badge,
  BoxProps,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { getCountFromServer, query, where } from 'firebase/firestore';
import moment from 'moment';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart } from 'react-icons/lu';
import { useFirestoreDoc } from 'reactfire';

import { useApplicationsCollectionRef } from '../../../collections/Applications';
import AppLanguage from '../../../common/AppLanguage';
import Catch from '../../../components/Catch';
import { useTripSnap } from '../../../components/snapProviders/TripSnapProvider';
import TripBudgetBadge from '../../../components/TripBudgetBadge';

export type Props = BoxProps;

export function TripDetailsMain({ ...boxProps }: Props) {
  const tripSnap = useTripSnap();
  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const { i18n, t } = useTranslation('PlansScreen', { keyPrefix: 'Trip.TripDetails' });
  const { t: tdt } = useTranslation('TripDuration');

  const [applicationsCount, setApplicationsCount] = useState<number>();

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      getCountFromServer(
        query(
          applicationsCollectionRef,
          where('tripRef', '==', tripSnap.ref),
        ),
      ).then((countSnap) => setApplicationsCount(countSnap.data().count)).catch(() => { });
    },
    [applicationsCollectionRef, tripSnap.ref],
  );

  const { data: destinationSnap } = useFirestoreDoc(tripDoc.destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      p={2}
    >
      <VStack alignItems="stretch" gap={0}>
        <HStack>
          <Text
            flex={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationDoc.name[i18n.language as AppLanguage]}
          </Text>

          <HStack flexShrink={0} gap={1}>
            <Icon as={LuHeart} />

            {typeof applicationsCount === 'number' ? (
              <Text>
                {applicationsCount}
              </Text>
            ) : (
              <Skeleton h={4} w={3} />
            )}

          </HStack>
        </HStack>

        <HStack gap={1}>
          <Text
            flexShrink={0}
            fontSize="sm"
          >
            {destinationDoc.countryEmoji}
          </Text>

          <Text
            flex={1}
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationDoc.countryName[i18n.language as AppLanguage]}
          </Text>

          <Text
            flexShrink={0}
            fontSize="sm"
          >
            {t('departure', {
              departure: moment(tripDoc.departure).toDate(),
              formatParams: { departure: { day: 'numeric', month: 'short' } },
            })}
          </Text>
        </HStack>
      </VStack>

      <Wrap>
        <TripBudgetBadge tripBudget={tripDoc.budget} />

        <Badge>
          {tdt(tripDoc.duration)}
        </Badge>
      </Wrap>
    </VStack>
  );
}

export default function TripDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
