import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import {
  arrayRemove,
  arrayUnion,
  doc,
  refEqual,
  setDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuBookmarkMinus, LuBookmarkPlus } from 'react-icons/lu';

import { useDestinationsCollectionRef } from '../../../collections/Destinations';
import AppLanguage from '../../../common/AppLanguage';
import DestinationAlgoliaSearchRecord from '../../../common/DestinationAlgoliaSearchRecord';
import { useAlgoliaInsightsClient } from '../../../components/AlgoliaInsightsClientProvider';
import { useInsets } from '../../../components/InsetsProvider';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';

export type Props = {
  destinationRecord: DestinationAlgoliaSearchRecord;
  index: number;
  queryId: string | undefined;
} & BoxProps;

export default function InfoCell({
  destinationRecord,
  index,
  queryId,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const algoliaInsights = useAlgoliaInsightsClient();

  const { i18n, t } = useTranslation('DestinationsScreen', { keyPrefix: 'Destination.InfoCell' });
  const { t: dlt } = useTranslation('DestinationLabels');

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  const destinationsCollectionRef = useDestinationsCollectionRef();

  const destinationRef = useMemo(
    () => doc(destinationsCollectionRef, destinationRecord.objectID),
    [destinationRecord.objectID, destinationsCollectionRef],
  );

  const myProfileSnap = useMyProfileSnap();

  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const isAdded = useMemo(
    () => _.some(
      myProfileDoc.wishlistDestinationRefs ?? [],
      (ref) => refEqual(ref, destinationRef),
    ),
    [destinationRef, myProfileDoc.wishlistDestinationRefs],
  );

  const handleAddClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDoc(
        myProfileSnap.ref,
        {
          wishlistDestinationRefs: arrayUnion(destinationRef),
        },
        { merge: true },
      )
        .then(async () => {
          mixpanel.track('Destination Added To Wishlist', {
            continentId: destinationRecord.continent?.id,
            continentName: destinationRecord.continent?.name,
            countryId: destinationRecord.countryId,
            countryName: destinationRecord.countryName.en,
            description: destinationRecord.description.en,
            destinationId: destinationRecord.objectID,
            name: destinationRecord.name.en,
          });

          if (queryId) {
            await algoliaInsights.pushEvents({
              events: [
                {
                  authenticatedUserToken: myProfileSnap.ref.id,
                  eventName: 'Destination Added To Wishlist',
                  eventType: 'conversion',
                  index: 'destinations',
                  objectIDs: [destinationRecord.objectID],
                  queryID: queryId,
                  timestamp: Date.now(),
                  userToken: myProfileSnap.ref.id,
                },
              ],
            });
          }
        })
        .catch(() => { });
    },
    [
      algoliaInsights,
      destinationRecord,
      destinationRef,
      myProfileSnap.ref,
      queryId,
    ],
  );

  const handleRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setDoc(
        myProfileSnap.ref,
        {
          wishlistDestinationRefs: arrayRemove(destinationRef),
        },
        { merge: true },
      )
        .then(() => {
          mixpanel.track('Destination Removed From Wishlist', {
            continentId: destinationRecord.continent?.id,
            continentName: destinationRecord.continent?.name,
            countryId: destinationRecord.countryId,
            countryName: destinationRecord.countryName.en,
            description: destinationRecord.description.en,
            destinationId: destinationRecord.objectID,
            name: destinationRecord.name.en,
          });
        })
        .catch(() => { });
    },
    [
      destinationRecord,
      destinationRef,
      myProfileSnap.ref,
    ],
  );

  const handleClick = useCallback(
    () => {
      onToggleDetails();

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: myProfileSnap.ref.id,
              eventName: 'Destination Details Clicked',
              eventType: 'click',
              index: 'destinations',
              objectIDs: [destinationRecord.objectID],
              positions: [index + 1],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: myProfileSnap.ref.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [
      onToggleDetails,
      queryId,
      algoliaInsights,
      myProfileSnap.ref.id,
      destinationRecord.objectID,
      index,
    ],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container maxW="lg">
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          cursor="pointer"
          onClick={handleClick}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={4}>
                <VStack alignItems="stretch" flex={1} gap={2}>
                  <Text fontSize="2xl" fontWeight="bold" lineHeight={1}>
                    {destinationRecord.name[i18n.language as AppLanguage]}
                  </Text>

                  <Text lineHeight={1}>
                    {_.compact([
                      destinationRecord.countryEmoji,
                      destinationRecord.countryName[i18n.language as AppLanguage],
                    ]).join(' ')}
                  </Text>
                </VStack>

                {isAdded ? (
                  <IconButton
                    aria-label={t('removeButton.default')}
                    colorScheme="red"
                    icon={<Icon as={LuBookmarkMinus} />}
                    onClick={handleRemoveClick}
                    size="lg"
                    variant="solid"
                  />
                ) : (
                  <IconButton
                    aria-label={t('addButton.default')}
                    colorScheme="green"
                    icon={<Icon as={LuBookmarkPlus} />}
                    onClick={handleAddClick}
                    size="lg"
                    variant="solid"
                  />
                )}
              </HStack>

              <Divider />

              <VStack
                alignItems="stretch"
                gap={4}
                overflow="auto"
                style={{
                  maxHeight: `calc(100vh - (${insets.top} + var(--chakra-space-4)) - (${insets.bottom} + var(--chakra-space-2)) - var(--chakra-space-12) - var(--chakra-space-16) - var(--chakra-space-1))`,
                }}
              >
                <Wrap>
                  {(destinationRecord.labels ?? []).map((label) => (
                    <Badge key={label}>{dlt(label)}</Badge>
                  ))}
                </Wrap>

                <Divider />

                <Text>
                  {destinationRecord.description[i18n.language as AppLanguage]}
                </Text>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}
