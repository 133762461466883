import { Box, BoxProps } from '@chakra-ui/react';
import { Suspense } from 'react';

import DestinationAlgoliaSearchRecord from '../../../common/DestinationAlgoliaSearchRecord';
import Catch from '../../../components/Catch';
import { useInsets } from '../../../components/InsetsProvider';
import UnsplashPictureCell from '../../../components/UnsplashPictureCell';
import InfoCell from './InfoCell';

export type Props = {
  destinationRecord: DestinationAlgoliaSearchRecord;
  height: number;
  queryId: string | undefined;
  searchResultPosition: number;
  width: number;
} & BoxProps;

export function DestinationMain({
  destinationRecord,
  height,
  queryId,
  searchResultPosition: index,
  width,
  ...boxProps
}: Props) {
  const insets = useInsets();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      position="relative"
    >
      <UnsplashPictureCell
        height={height}
        picture={destinationRecord.picture}
        width={width}
      />

      <InfoCell
        destinationRecord={destinationRecord}
        index={index}
        left={0}
        position="absolute"
        queryId={queryId}
        right={0}
        style={{
          bottom: `calc(${insets.bottom} + var(--chakra-space-2))`,
        }}
        zIndex={20}
      />
    </Box>
  );
}

export default function Destination(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <DestinationMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
