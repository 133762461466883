import { useRadioGroup, UseRadioGroupProps, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';

import TripBudget from '../../common/TripBudget';
import { useMyProfileHiddenSnap } from '../snapProviders/MyProfileHiddenSnapProvider';
import EconomyCard from './EconomyCard';
import LuxuryCard from './LuxuryCard';
import PremiumCard from './PremiumCard';
import StandardCard from './StandardCard';
import UltimateCard from './UltimateCard';

export default function BudgetRadioGroup(props: UseRadioGroupProps) {
  const { getRadioProps, getRootProps } = useRadioGroup(props);

  const group = getRootProps();

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const radioEconomy = getRadioProps({
    value: TripBudget.ECONOMY,
  });

  const showStandard = useMemo(
    () => [
      TripBudget.LUXURY,
      TripBudget.PREMIUM,
      TripBudget.STANDARD,
      TripBudget.ULTIMATE,
    ].includes(myProfileHiddenDoc.maxTripBudget),
    [myProfileHiddenDoc.maxTripBudget],
  );

  const radioStandard = getRadioProps({
    isDisabled: !showStandard,
    value: TripBudget.STANDARD,
  });

  const showPremium = useMemo(
    () => [
      TripBudget.LUXURY,
      TripBudget.PREMIUM,
      TripBudget.ULTIMATE,
    ].includes(myProfileHiddenDoc.maxTripBudget),
    [myProfileHiddenDoc.maxTripBudget],
  );

  const radioPremium = getRadioProps({
    isDisabled: !showPremium,
    value: TripBudget.PREMIUM,
  });

  const showLuxury = useMemo(
    () => [
      TripBudget.LUXURY,
      TripBudget.ULTIMATE,
    ].includes(myProfileHiddenDoc.maxTripBudget),
    [myProfileHiddenDoc.maxTripBudget],
  );

  const radioLuxury = getRadioProps({
    isDisabled: !showLuxury,
    value: TripBudget.LUXURY,
  });

  const showUltimate = useMemo(
    () => [
      TripBudget.ULTIMATE,
    ].includes(myProfileHiddenDoc.maxTripBudget),
    [myProfileHiddenDoc.maxTripBudget],
  );

  const radioUltimate = getRadioProps({
    isDisabled: !showUltimate,
    value: TripBudget.ULTIMATE,
  });

  return (
    <VStack
      alignItems="stretch"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...group}
    >
      <EconomyCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioEconomy}
      />

      {showStandard ? (
        <StandardCard
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioStandard}
        />
      ) : null}

      {showPremium ? (
        <PremiumCard
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioPremium}
        />
      ) : null}

      {showLuxury ? (
        <LuxuryCard
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioLuxury}
        />
      ) : null}

      {showUltimate ? (
        <UltimateCard
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...radioUltimate}
        />
      ) : null}
    </VStack>
  );
}
