import {
  BoxProps,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  getCountFromServer,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuGift, LuPlane } from 'react-icons/lu';

import { DestinationDoc } from '../../../collections/Destinations';
import { useProfilesCollectionRef } from '../../../collections/Profiles';
import { useTripsCollectionRef } from '../../../collections/Trips';
import AppLanguage from '../../../common/AppLanguage';
import Catch from '../../../components/Catch';

export type Props = {
  destinationSnap: QueryDocumentSnapshot<DestinationDoc>;
} & BoxProps;

export function DestinationDetailsMain({ destinationSnap, ...boxProps }: Props) {
  const { i18n } = useTranslation('WishlistScreen', { keyPrefix: 'Destination.DestinationDetails' });

  const tripsCollectionRef = useTripsCollectionRef();
  const [tripsCount, setTripsCount] = useState<number>();
  useEffect(
    () => {
      getCountFromServer(
        query(
          tripsCollectionRef,
          where('destinationRef', '==', destinationSnap.ref),
        ),
      ).then((countSnap) => setTripsCount(countSnap.data().count)).catch(() => { });
    },
    [destinationSnap.ref, tripsCollectionRef],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const [profilesCount, setProfilesCount] = useState<number>();
  useEffect(
    () => {
      getCountFromServer(
        query(
          profilesCollectionRef,
          where('wishlistDestinationRefs', 'array-contains', destinationSnap.ref),
        ),
      ).then((countSnap) => setProfilesCount(countSnap.data().count)).catch(() => { });
    },
    [destinationSnap.ref, profilesCollectionRef],
  );

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  return (
    <VStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      alignItems="stretch"
      gap={2}
      p={2}
    >
      <VStack alignItems="stretch" gap={0}>
        <HStack>
          <Text
            flex={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationDoc.name[i18n.language as AppLanguage]}
          </Text>

          <HStack flexShrink={0} gap={1}>
            <Icon as={LuPlane} />

            {typeof tripsCount === 'number' ? (
              <Text>
                {tripsCount}
              </Text>
            ) : (
              <Skeleton h={4} w={3} />
            )}
          </HStack>
        </HStack>

        <HStack gap={1}>
          <Text
            flexShrink={0}
            fontSize="sm"
          >
            {destinationDoc.countryEmoji}
          </Text>

          <Text
            flex={1}
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {destinationDoc.countryName[i18n.language as AppLanguage]}
          </Text>

          <HStack flexShrink={0} gap={1}>
            <Icon as={LuGift} />

            {typeof profilesCount === 'number' ? (
              <Text>
                {profilesCount}
              </Text>
            ) : (
              <Skeleton h={4} w={3} />
            )}
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default function DestinationDetails(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <DestinationDetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
