import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { LuLanguages, LuSparkles } from 'react-icons/lu';
import { PiGenderFemaleBold, PiGenderIntersexBold, PiGenderMaleBold } from 'react-icons/pi';
import { useFirestoreDoc } from 'reactfire';

import { isProfileComplete } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Gender from '../../../common/Gender';
import Sexuality from '../../../common/Sexuality';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import Catch from '../../../components/Catch';
import { useInsets } from '../../../components/InsetsProvider';
import { useProfileSnap } from '../../../components/snapProviders/ProfileSnapProvider';
import TierIcon from '../../../components/TierIcon';
import ActionCell from './ActionCell';
import Admin from './Admin';
import ProfileAvatar from './ProfileAvatar';

export type Props = BoxProps;

export function InfoCellMain({
  ...boxProps
}: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const insets = useInsets();

  if (!isProfileComplete(profileDoc)) {
    throw new Error('Profile is not complete');
  }

  const { i18n } = useTranslation('ProfileScreen', { keyPrefix: 'Profile.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: tt } = useTranslation('Tier');
  const { t: gt } = useTranslation('Gender');
  const { t: st } = useTranslation('Sexuality');
  const { t: zt } = useTranslation('Zodiac');

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data: originSnap } = useFirestoreDoc(profileDoc.originRef);

  if (!originSnap.exists()) {
    throw new Error('Origin does not exist');
  }

  const originDoc = useMemo(() => originSnap.data(), [originSnap]);

  const handleClick = useCallback(
    () => {
      mixpanel.track(!isOpen ? 'Profile Details Opened' : 'Profile Details Closed', {
        age: profileDoc.age,
        createdAt: profileDoc.createdAt.toDate(),
        gender: profileDoc.gender,
        height: profileDoc.height,
        languages: profileDoc.languages,
        name: profileDoc.name,
        originCityId: profileDoc.originRef.id,
        originCountryId: originDoc.countryRef?.id,
        profileId: profileSnap.id,
        score: profileDoc.score,
        sexuality: profileDoc.sexuality,
        tier: profileDoc.tier,
        weight: profileDoc.weight,
        zodiac: profileDoc.zodiac,
      });

      if (!isOpen) { onOpen(); } else { onClose(); }
    },
    [
      isOpen,
      onClose,
      onOpen,
      originDoc.countryRef?.id,
      profileDoc.age,
      profileDoc.createdAt,
      profileDoc.gender,
      profileDoc.height,
      profileDoc.languages,
      profileDoc.name,
      profileDoc.originRef.id,
      profileDoc.score,
      profileDoc.sexuality,
      profileDoc.tier,
      profileDoc.weight,
      profileDoc.zodiac,
      profileSnap.id,
    ],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container maxW="lg">
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          className="profileInfo"
          cursor="pointer"
          onClick={handleClick}
          p={4}
        >
          <Collapse
            in={isOpen}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={4}>
                <ProfileAvatar />

                <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
                  <HStack gap={1}>
                    <TierIcon boxSize={4} tier={profileDoc.tier} />

                    <Text
                      flexShrink={1}
                      fontSize="md"
                      lineHeight={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {/* eslint-disable-next-line max-len */}
                      {profileDoc.translations?.name[i18n.language as AppLanguage] ?? profileDoc.name}
                      {', '}
                      {profileDoc.age}
                    </Text>

                    {profileDoc.instagramTag && (
                      <Icon as={FaInstagram} boxSize={4} />
                    )}

                    {profileDoc.tiktokTag && (
                      <Icon as={FaTiktok} boxSize={4} />
                    )}

                    {profileDoc.linkedinTag && (
                      <Icon as={FaLinkedin} boxSize={4} />
                    )}
                  </HStack>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {_.compact([
                      originDoc.name,
                      originDoc.countryName,
                    ]).join(', ')}
                  </Text>
                </VStack>

                <ActionCell />
              </HStack>

              <Divider />

              <VStack
                alignItems="stretch"
                gap={4}
                overflow="auto"
                style={{
                  maxHeight: `calc(100vh - (${insets.top} + var(--chakra-space-4)) - (${insets.bottom} + var(--chakra-space-2)) - var(--chakra-space-12) - var(--chakra-space-16) - var(--chakra-space-1))`,
                }}
              >
                <Admin />

                <Wrap>
                  {profileDoc.tier === Tier.MORTAL ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.MORTAL].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.MORTAL].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.HERMES ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERMES].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERMES].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.DIONYSUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.DIONYSUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.DIONYSUS].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.POSEIDON ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.POSEIDON].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.POSEIDON].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.ZEUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ZEUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ZEUS].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.ARTEMIS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ARTEMIS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ARTEMIS].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.ATHENA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ATHENA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ATHENA].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.APHRODITE ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.APHRODITE].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.APHRODITE].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.tier === Tier.HERA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERA].icon} boxSize={3} />
                      {tt(`${profileDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {profileDoc.gender === Gender.FEMALE ? (
                    <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                      <Icon as={PiGenderFemaleBold} boxSize={3} />
                      {gt(profileDoc.gender)}
                    </Badge>
                  ) : null}

                  {profileDoc.gender === Gender.MALE ? (
                    <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                      <Icon as={PiGenderMaleBold} boxSize={3} />
                      {gt(profileDoc.gender)}
                    </Badge>
                  ) : null}

                  {
                    profileDoc.sexuality === Sexuality.STRAIGHT
                      && profileDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.STRAIGHT
                      && profileDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.GAY
                      && profileDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.GAY
                      && profileDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(profileDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    profileDoc.sexuality === Sexuality.BISEXUAL ? (
                      <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                        <Icon as={PiGenderIntersexBold} size={5} />
                        {st(profileDoc.sexuality)}
                      </Badge>
                    ) : null
                  }

                  {profileDoc.instagramTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.instagram.com/${profileDoc.instagramTag}`}
                      target="_blank"
                    >
                      <Icon as={FaInstagram} size={5} />
                      {profileDoc.instagramTag}
                    </Badge>
                  )}

                  {profileDoc.tiktokTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.tiktok.com/@${profileDoc.tiktokTag}`}
                      target="_blank"
                    >
                      <Icon as={FaTiktok} size={5} />
                      {profileDoc.tiktokTag}
                    </Badge>
                  )}

                  {profileDoc.linkedinTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.linkedin.com/in/${profileDoc.linkedinTag}`}
                      target="_blank"
                    >
                      <Icon as={FaLinkedin} size={5} />
                      {profileDoc.linkedinTag}
                    </Badge>
                  )}

                  {profileDoc.languages.map((l) => (
                    <Badge alignItems="center" display="flex" gap={1} key={l}>
                      <Icon as={LuLanguages} size={5} />
                      {lt(l)}
                    </Badge>
                  ))}

                  <Badge>
                    {profileDoc.height}
                    cm
                  </Badge>

                  <Badge>
                    {profileDoc.weight}
                    kg
                  </Badge>

                  <Badge>
                    {profileDoc.age}
                    y
                  </Badge>

                  <Badge alignItems="center" display="flex" gap={1}>
                    <Icon as={LuSparkles} size={5} />
                    {zt(profileDoc.zodiac)}
                  </Badge>

                  {originDoc.name ? (
                    <Badge>
                      {originDoc.name}
                    </Badge>
                  ) : null}

                  {originDoc.countryName ? (
                    <Badge>
                      {originDoc.countryName}
                    </Badge>
                  ) : null}
                </Wrap>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}

export default function InfoCell(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <InfoCellMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
