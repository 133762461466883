import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import BannedAlertProvider from './components/BannedAlertProvider';
import ApplicationsScreen from './screens/ApplicationsScreen';
import ChatScreen from './screens/ChatScreen';
import ChatsScreen from './screens/ChatsScreen';
import CheckMyProfileSnapLayout from './screens/CheckMyProfileSnapLayout';
import CheckProfileRefLayout from './screens/CheckProfileRefLayout';
import ConversationParticipantsScreen from './screens/ConversationParticipantsScreen';
import DestinationCreateScreen from './screens/DestinationCreateScreen';
import DestinationsScreen from './screens/DestinationsScreen';
import DestinationUpdateScreen from './screens/DestinationUpdateScreen';
import EventsScreen from './screens/EventsScreen';
import HomeScreen from './screens/HomeScreen';
import MainLayout from './screens/MainLayout';
import MyProfileScreen from './screens/MyProfileScreen';
import MyProfileUpdateScreen from './screens/MyProfileUpdateScreen';
import PlansScreen from './screens/PlansScreen';
import ProfileScreen from './screens/ProfileScreen';
import SettingsScreen from './screens/SettingsScreen';
import TripCreateInitScreen from './screens/TripCreateInitScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripUpdateScreen from './screens/TripUpdateScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import WishlistScreen from './screens/WishlistScreen';

export default function Navigation() {
  return (
    <>
      <Helmet defaultTitle="Qupidu - Exclusive Travel Dating" titleTemplate="%s | Qupidu" />

      <BrowserRouter>
        <BannedAlertProvider>
          <Routes>
            <Route element={<WelcomeScreen />} path="welcome" />

            <Route element={<CheckProfileRefLayout fallback={<Navigate replace to="/welcome" />} />}>
              <Route element={<CheckMyProfileSnapLayout fallback={<Navigate replace to="/welcome" />} />}>
                <Route element={<MainLayout />} path="/">
                  <Route element={<HomeScreen />} index />

                  <Route path="profiles">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ProfileScreen />} path=":profileId" />
                  </Route>

                  <Route path="applications">
                    <Route element={<ApplicationsScreen />} index />
                    <Route element={<Navigate replace to=".." />} path=":applicationId" />
                  </Route>

                  <Route path="chats">
                    <Route element={<ChatsScreen />} index />
                    <Route path=":conversationId">
                      <Route element={<ChatScreen />} index />
                      <Route element={<ConversationParticipantsScreen />} path="participants" />
                    </Route>
                  </Route>

                  <Route element={<Navigate replace to="/chats" />} path="conversations/*" />

                  <Route path="plans">
                    <Route element={<PlansScreen />} index />
                    <Route path="add">
                      <Route element={<TripCreateInitScreen />} index />
                      <Route element={<TripCreateScreen />} path=":destinationId" />
                    </Route>
                    <Route element={<TripUpdateScreen />} path=":tripId" />
                  </Route>

                  <Route element={<Navigate replace to="/plans" />} path="trips/*" />

                  <Route path="wishlist">
                    <Route element={<WishlistScreen />} index />
                    <Route element={<DestinationsScreen />} path="destinations" />
                  </Route>

                  <Route path="events">
                    <Route element={<EventsScreen />} index />
                  </Route>

                  <Route path="destinations">
                    <Route element={<DestinationCreateScreen />} path="add" />
                    <Route element={<DestinationUpdateScreen />} path=":destinationId" />
                  </Route>

                  <Route path="profile">
                    <Route element={<MyProfileScreen />} index />
                    <Route element={<MyProfileUpdateScreen />} path="update" />
                    <Route element={<SettingsScreen />} path="settings" />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </BannedAlertProvider>
      </BrowserRouter>
    </>
  );
}
