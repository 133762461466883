import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import Gender from '../../common/Gender';
import useShowError from '../../hooks/useShowError';
import GenderRadioGroup from './GenderRadioGroup';

export interface Props {
  label: string;
  name: string;
}

export default function GenderFormControl({
  label,
  name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<Gender>(name);

  const handleChange = useCallback(
    (value: Gender) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <GenderRadioGroup name={name} onChange={handleChange} value={input.value} />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
