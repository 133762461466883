import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { isNil } from 'lodash';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import Gender from '../common/Gender';
import Language from '../common/Language';
import Sexuality from '../common/Sexuality';
import { StoragePicture } from '../common/StoragePicture';
import Tier from '../common/Tier';
import TripBudget from '../common/TripBudget';
import Zodiac from '../common/Zodiac';
import { type CityDoc } from './Cities';
import { type DestinationDoc } from './Destinations';
import { type TripDuration } from './Trips';

export enum ProfileStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface ProfileDoc {
  _v: 1;
  age?: number;
  bannedAt?: Timestamp;
  createdAt: Timestamp;
  expiresAt: Timestamp;
  extraScore?: number;
  gender: Gender;
  height?: number;
  indexedAt?: Timestamp;
  instagramTag?: string;
  isBanned?: boolean;
  languages?: Language[];
  lastNotificationAt: Timestamp;
  lastSeenAt: Timestamp;
  linkedinTag?: string;
  name?: string;
  originRef?: DocumentReference<CityDoc>;
  pictures?: StoragePicture[];
  primaryPictureIndex?: number;
  score: number;
  scoredAt: Timestamp;
  sexuality: Sexuality;
  status: ProfileStatus;
  stripeAccountId?: string;
  tier: Tier;
  tiktokTag?: string;
  translations?: {
    name: Record<AppLanguage, string>;
  };
  updatedAt: Timestamp;
  weight?: number;
  wishlistDestinationRefs?: DocumentReference<DestinationDoc>[];
  zodiac?: Zodiac;
}

export type ProfileCompleteDoc = ProfileDoc & Required<Pick<ProfileDoc, 'age' | 'height' | 'languages' | 'name' | 'originRef' | 'pictures' | 'weight' | 'zodiac'>>;

export const isProfileComplete = (profileDoc: ProfileDoc): profileDoc is ProfileCompleteDoc => {
  if (isNil(profileDoc.age)) {
    return false;
  }

  if (isNil(profileDoc.height)) {
    return false;
  }

  if (isNil(profileDoc.languages)) {
    return false;
  }

  if (isNil(profileDoc.name)) {
    return false;
  }

  if (isNil(profileDoc.originRef)) {
    return false;
  }

  if (isNil(profileDoc.pictures)) {
    return false;
  }

  if (isNil(profileDoc.weight)) {
    return false;
  }

  if (isNil(profileDoc.zodiac)) {
    return false;
  }

  return true;
};

export const isProfileDoc = (
  data?: DocumentData,
): data is ProfileDoc => true;

export const isProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileDoc> => ref.parent.id === 'profiles';

function isProfileSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc>;
function isProfileSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileDoc> | QueryDocumentSnapshot<ProfileDoc> {
  return isProfileRef(snap.ref);
}

export { isProfileSnap };

export const getProfilesCollectionRef = (firestore: Firestore) => collection(firestore, 'profiles') as CollectionReference<ProfileDoc>;

export const useProfilesCollectionRef = () => {
  const firestore = useFirestore();
  return getProfilesCollectionRef(firestore);
};

export interface ProfileHiddenDoc {
  _v: 1;
  appLanguage?: AppLanguage;
  birthDate?: string;
  fcmTokens?: string[];
  maxTripBudget: TripBudget;
  preferences?: {
    age?: {
      max: number;
      min: number;
    };
    height?: {
      max: number;
      min: number;
    };
    tiers?: Tier[];
    tripBudgets?: TripBudget[];
    tripDurations?: TripDuration[];
    weight?: {
      max: number;
      min: number;
    };
  };
  profileSuggestions?: string[];
  timezone?: string;
}

export const isProfileHiddenDoc = (
  data?: DocumentData,
): data is ProfileHiddenDoc => true;

export const isProfileHiddenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ProfileHiddenDoc> => ref.id === 'internal' && ref.parent.id === 'hidden' && ref.parent.parent !== null && isProfileRef(ref.parent.parent);

function isProfileHiddenSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc>;
function isProfileHiddenSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ProfileHiddenDoc> | QueryDocumentSnapshot<ProfileHiddenDoc> {
  return isProfileHiddenRef(snap.ref);
}

export { isProfileHiddenSnap };

export const getProfileHiddenRef = (userRef: DocumentReference<ProfileDoc>) => doc(collection(userRef, 'internal'), 'hidden') as DocumentReference<ProfileHiddenDoc>;
