import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Icon,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  and,
  doc,
  getDocs,
  limit,
  or,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import mixpanel, { Dict } from 'mixpanel-browser';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuCheck, LuHeart, LuMessageSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../../../collections/Conversations';
import { isProfileComplete, useProfilesCollectionRef } from '../../../../collections/Profiles';
import { TripStatus, useTripsCollectionRef } from '../../../../collections/Trips';
import TripAlgoliaSearchRecord from '../../../../common/TripAlgoliaSearchRecord';
import { useAlgoliaInsightsClient } from '../../../../components/AlgoliaInsightsClientProvider';
import { useBannedAlert } from '../../../../components/BannedAlertProvider';
import Catch from '../../../../components/Catch';
import { useMyProfileSnap } from '../../../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../../../hooks/useShowError';

export type Props = {
  queryId: string | undefined;
  tripRecord: TripAlgoliaSearchRecord;
};

export function ActionCellMain({
  queryId, tripRecord,
}: Props) {
  const { t } = useTranslation('TripsScreen', { keyPrefix: 'Trip' });

  const mixpanelData = useMemo<Dict>(() => ({
    budget: tripRecord.budget,
    createdAt: new Date(tripRecord.createdAt),
    departure: tripRecord.departure,
    destinationCountryId: tripRecord.destinationCountryId,
    destinationId: tripRecord.destinationId,
    duration: tripRecord.duration,
    organizerAge: tripRecord.organizer.age,
    organizerGender: tripRecord.organizer.gender,
    organizerHeight: tripRecord.organizer.height,
    organizerId: tripRecord.organizer.id,
    organizerLanguages: tripRecord.organizer.languages,
    organizerName: tripRecord.organizer.name,
    organizerOriginCityId: tripRecord.organizer.origin.city.id,
    organizerOriginCountryId: tripRecord.organizer.origin.country?.id,
    organizerScore: tripRecord.organizer.score,
    organizerSexuality: tripRecord.organizer.sexuality,
    organizerSexualityGender: tripRecord.organizer.sexualityGender,
    organizerTier: tripRecord.organizer.tier,
    organizerWeight: tripRecord.organizer.weight,
    organizerZodiac: tripRecord.organizer.zodiac,
    tripId: tripRecord.objectID,
  }), [tripRecord]);

  const algoliaInsights = useAlgoliaInsightsClient();

  const showError = useShowError();
  const toast = useToast();

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const showBannedAlert = useBannedAlert();

  const tripsCollectionRef = useTripsCollectionRef();
  const tripRef = useMemo(
    () => doc(tripsCollectionRef, tripRecord.objectID),
    [tripRecord.objectID, tripsCollectionRef],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, tripRecord.organizer.id),
    [profilesCollectionRef, tripRecord.organizer.id],
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: outgoingApplicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', profileRef),
      where('applicantRef', '==', myProfileSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      limit(1),
    ),
  );

  const outgoingApplicationSnap = useMemo(
    () => (outgoingApplicationsSnap.docs.length ? outgoingApplicationsSnap.docs[0] : undefined),
    [outgoingApplicationsSnap.docs],
  );

  const { data: incomingApplicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('applicantRef', '==', profileRef),
      where('status', '==', ApplicationStatus.SENT),
      limit(1),
    ),
  );

  const incomingApplicationSnap = useMemo(
    () => (incomingApplicationsSnap.docs.length ? incomingApplicationsSnap.docs[0] : undefined),
    [incomingApplicationsSnap.docs],
  );

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      and(
        or(
          where('participantRefs', '==', [myProfileSnap.ref, profileRef]),
          where('participantRefs', '==', [profileRef, myProfileSnap.ref]),
        ),
        where('status', '==', ConversationStatus.OPENED),
      ),
      limit(1),
    ),
  );

  const conversationSnap = useMemo(
    () => (conversationsSnap.docs.length ? conversationsSnap.docs[0] : undefined),
    [conversationsSnap.docs],
  );

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleApplyClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Trip Apply Clicked', mixpanelData);

      if (myProfileDoc?.isBanned) {
        showBannedAlert();
        return;
      }

      setLoading(true);

      if (tripRecord.status !== TripStatus.PUBLISHED) {
        throw new Error('Trip should be published');
      }

      const existingApplicationsSnap = await getDocs(
        query(
          applicationsCollectionRef,
          where('applicantRef', '==', myProfileSnap.ref),
          where('organizerRef', '==', profileRef),
          where('tripRef', '==', tripRef),
          where('status', '==', ApplicationStatus.SENT),
        ),
      );

      if (existingApplicationsSnap.docs.length > 0) {
        throw new Error('Trip Join Request already exists');
      }

      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileSnap.ref,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef: profileRef,
          sentAt: serverTimestamp(),
          status: ApplicationStatus.SENT,
          tripRef,
        },
      );

      toast({
        isClosable: true,
        status: 'success',
        title: t('applyConfirmation'),
      });

      mixpanel.track('Trip Application Sent', mixpanelData);

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: myProfileSnap.ref.id,
              eventName: 'Trip Applied',
              eventType: 'conversion',
              index: 'trips',
              objectIDs: [tripRecord.objectID],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: myProfileSnap.ref.id,
            },
          ],
        }).catch(() => { });
      }

      setLoading(false);
    },
    [
      algoliaInsights,
      applicationsCollectionRef,
      mixpanelData,
      myProfileDoc?.isBanned,
      myProfileSnap.ref,
      profileRef,
      queryId,
      showBannedAlert,
      t,
      toast,
      tripRecord,
      tripRef,
    ],
  );

  const firestore = useFirestore();
  const navigate = useNavigate();

  const handleAcceptClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (incomingApplicationSnap === undefined) {
        throw new Error('Incoming Application not found');
      }

      e.stopPropagation();

      mixpanel.track('Trip Accept Clicked', mixpanelData);

      setLoading(true);

      const conversationRef = doc(conversationsCollectionRef);

      const batch = writeBatch(firestore);

      batch.set(
        conversationRef,
        {
          _v: 1,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          lastActionAt: Timestamp.now(),
          notReadByRefs: [],
          openedAt: Timestamp.now(),
          participantRefs: [myProfileSnap.ref, profileRef],
          readByRefs: [],
          status: ConversationStatus.OPENED,
          tripRefs: [],
          typingParticipantRefs: [],
        },
      );

      batch.set(
        incomingApplicationSnap.ref,
        {
          acceptedAt: Timestamp.now(),
          status: ApplicationStatus.ACCEPTED,
        },
        { merge: true },
      );

      batch.commit()
        .then(() => {
          setLoading(false);
          navigate(`/chats/${conversationRef.id}`);
        })
        .catch((err) => {
          setLoading(false);
          showError(err);
        });

      mixpanel.track('Application Accepted', {
        acceptedAt: new Date(),
        applicantId: incomingApplicationSnap.data().applicantRef.id,
        applicationId: incomingApplicationSnap.id,
        organizerId: incomingApplicationSnap.data().organizerRef.id,
        sentAt: incomingApplicationSnap.data().sentAt.toDate(),
        status: ApplicationStatus.ACCEPTED,
      });
    },
    [
      conversationsCollectionRef,
      firestore,
      incomingApplicationSnap,
      mixpanelData,
      myProfileSnap.ref,
      navigate,
      profileRef,
      showError,
    ],
  );

  const handleMessageClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!conversationSnap) {
        throw new Error('Conversation not found');
      }

      mixpanel.track('Trip Conversation Clicked', mixpanelData);

      navigate(`/chats/${conversationSnap.id}`);
    },
    [
      conversationSnap,
      mixpanelData,
      navigate,
    ],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (myProfileDoc && isProfileComplete(myProfileDoc)) {
    if (conversationSnap) {
      return (
        <IconButton
          aria-label={t('sendMessageButton.default')}
          className="tripAction"
          icon={<Icon as={LuMessageSquare} />}
          onClick={handleMessageClick}
          size="lg"
          variant="solid"
        />
      );
    }

    if (incomingApplicationSnap) {
      return (
        <IconButton
          aria-label={t('acceptButton.default')}
          className="tripAction"
          colorScheme="green"
          icon={<Icon as={LuCheck} />}
          isLoading={isLoading}
          onClick={handleAcceptClick}
          size="lg"
          variant="solid"
        />
      );
    }

    if (outgoingApplicationSnap) {
      return (
        <IconButton
          aria-label={t('applyButton.disabled')}
          className="tripAction"
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          isDisabled
          size="lg"
          variant="solid"
        />
      );
    }

    return (
      <IconButton
        aria-label={t('applyButton.default')}
        className="tripAction"
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        isLoading={isLoading}
        onClick={(e) => { handleApplyClick(e).catch(showError); }}
        size="lg"
        variant="solid"
      />
    );
  }

  return (
    <>
      <IconButton
        aria-label={t('applyButton.default')}
        className="tripAction"
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
        size="lg"
        variant="solid"
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogContent
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('applyAlertModal.title')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('applyAlertModal.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} variant="ghost">
              {t('applyAlertModal.cancelButton.default')}
            </Button>

            <Button as={Link} ml={3} to="/profile/update">
              {t('applyAlertModal.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default function ActionCell(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label="Loading"
            className="tripAction"
            colorScheme="pink"
            icon={<Icon as={LuHeart} />}
            isLoading
            size="lg"
            variant="solid"
          />
        )}
      >
        <ActionCellMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
