import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { LuLanguages, LuSparkles } from 'react-icons/lu';
import { PiGenderFemaleBold, PiGenderIntersexBold, PiGenderMaleBold } from 'react-icons/pi';
import { useFirestoreDoc } from 'reactfire';

import { isProfileComplete } from '../../../collections/Profiles';
import AppLanguage from '../../../common/AppLanguage';
import Gender from '../../../common/Gender';
import Sexuality from '../../../common/Sexuality';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import Catch from '../../../components/Catch';
import { useApplicationSnap } from '../../../components/snapProviders/ApplicationSnapProvider';
import TierIcon from '../../../components/TierIcon';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ActionCell from './ActionCell';
import ApplicantAvatar from './ApplicantAvatar';

export type Props = BoxProps;

export function InfoCellMain({
  ...boxProps
}: Props) {
  const applicationSnap = useApplicationSnap();

  const { i18n } = useTranslation('ApplicationsScreen', { keyPrefix: 'Application.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: tt } = useTranslation('Tier');
  const { t: gt } = useTranslation('Gender');
  const { t: st } = useTranslation('Sexuality');
  const { t: zt } = useTranslation('Zodiac');

  const { height } = useWindowDimensions();

  const applicationDoc = useMemo(() => applicationSnap.data(), [applicationSnap]);

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  useEffect(
    () => {
      mixpanel.track(isOpenDetails ? 'Application Details Opened' : 'Application Details Closed', {
        acceptedAt: applicationDoc.acceptedAt?.toDate(),
        applicantId: applicationDoc.applicantRef.id,
        applicationId: applicationSnap.id,
        organizerId: applicationDoc.organizerRef.id,
        rejectedAt: applicationDoc.rejectedAt?.toDate(),
        rejectedBy: applicationDoc.rejectedBy,
        sentAt: applicationDoc.sentAt.toDate(),
        status: applicationDoc.status,
        tripId: applicationDoc.tripRef?.id,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpenDetails],
  );

  const { data: applicantSnap } = useFirestoreDoc(applicationDoc.applicantRef);

  if (!applicantSnap.exists()) {
    throw new Error('Applicant does not exist');
  }

  const applicantDoc = useMemo(() => applicantSnap.data(), [applicantSnap]);

  if (!isProfileComplete(applicantDoc)) {
    throw new Error('Profile is not complete');
  }

  const { data: originSnap } = useFirestoreDoc(applicantDoc.originRef);

  if (!originSnap.exists()) {
    throw new Error('Origin does not exist');
  }

  const originDoc = useMemo(() => originSnap.data(), [originSnap]);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container maxW="lg">
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          cursor="pointer"
          onClick={onToggleDetails}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={4}>
                <ApplicantAvatar />

                <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
                  <HStack gap={1}>
                    <TierIcon boxSize={4} tier={applicantDoc.tier} />

                    <Text
                      flexShrink={1}
                      fontSize="md"
                      lineHeight={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {/* eslint-disable-next-line max-len */}
                      {applicantDoc.translations?.name[i18n.language as AppLanguage] ?? applicantDoc.name}
                      {', '}
                      {applicantDoc.age}
                    </Text>

                    {applicantDoc.instagramTag && (
                      <Icon as={FaInstagram} boxSize={4} />
                    )}

                    {applicantDoc.tiktokTag && (
                      <Icon as={FaTiktok} boxSize={4} />
                    )}

                    {applicantDoc.linkedinTag && (
                      <Icon as={FaLinkedin} boxSize={4} />
                    )}
                  </HStack>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {_.compact([
                      originDoc.name,
                      originDoc.countryName,
                    ]).join(', ')}
                  </Text>
                </VStack>

                <ActionCell />
              </HStack>

              <Divider />

              <VStack alignItems="stretch" gap={4} maxH={`${height - 274}px`} overflow="auto">
                <Wrap>
                  {applicantDoc.tier === Tier.MORTAL ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.MORTAL].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.MORTAL].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.HERMES ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERMES].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERMES].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.DIONYSUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.DIONYSUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.DIONYSUS].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.POSEIDON ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.POSEIDON].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.POSEIDON].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.ZEUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ZEUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ZEUS].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.ARTEMIS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ARTEMIS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ARTEMIS].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.ATHENA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ATHENA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ATHENA].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.APHRODITE ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.APHRODITE].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.APHRODITE].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.tier === Tier.HERA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERA].icon} boxSize={3} />
                      {tt(`${applicantDoc.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {applicantDoc.gender === Gender.FEMALE ? (
                    <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                      <Icon as={PiGenderFemaleBold} size={5} />
                      {gt(applicantDoc.gender)}
                    </Badge>
                  ) : null}

                  {applicantDoc.gender === Gender.MALE ? (
                    <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                      <Icon as={PiGenderMaleBold} size={5} />
                      {gt(applicantDoc.gender)}
                    </Badge>
                  ) : null}

                  {
                    applicantDoc.sexuality === Sexuality.STRAIGHT
                      && applicantDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(applicantDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    applicantDoc.sexuality === Sexuality.STRAIGHT
                      && applicantDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(applicantDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    applicantDoc.sexuality === Sexuality.GAY
                      && applicantDoc.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(applicantDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    applicantDoc.sexuality === Sexuality.GAY
                      && applicantDoc.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(applicantDoc.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    applicantDoc.sexuality === Sexuality.BISEXUAL ? (
                      <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                        <Icon as={PiGenderIntersexBold} size={5} />
                        {st(applicantDoc.sexuality)}
                      </Badge>
                    ) : null
                  }

                  {applicantDoc.instagramTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.instagram.com/${applicantDoc.instagramTag}`}
                      target="_blank"
                    >
                      <Icon as={FaInstagram} size={5} />
                      {applicantDoc.instagramTag}
                    </Badge>
                  )}

                  {applicantDoc.tiktokTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.tiktok.com/@${applicantDoc.tiktokTag}`}
                      target="_blank"
                    >
                      <Icon as={FaTiktok} size={5} />
                      {applicantDoc.tiktokTag}
                    </Badge>
                  )}

                  {applicantDoc.linkedinTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.linkedin.com/in/${applicantDoc.linkedinTag}`}
                      target="_blank"
                    >
                      <Icon as={FaLinkedin} size={5} />
                      {applicantDoc.linkedinTag}
                    </Badge>
                  )}

                  {applicantDoc.languages.map((l) => (
                    <Badge alignItems="center" display="flex" gap={1} key={l}>
                      <Icon as={LuLanguages} size={5} />
                      {lt(l)}
                    </Badge>
                  ))}

                  <Badge>
                    {applicantDoc.height}
                    cm
                  </Badge>

                  <Badge>
                    {applicantDoc.weight}
                    kg
                  </Badge>

                  <Badge>
                    {applicantDoc.age}
                    y
                  </Badge>

                  <Badge alignItems="center" display="flex" gap={1}>
                    <Icon as={LuSparkles} size={5} />
                    {zt(applicantDoc.zodiac)}
                  </Badge>

                  {originDoc.name ? (
                    <Badge>
                      {originDoc.name}
                    </Badge>
                  ) : null}

                  {originDoc.countryName ? (
                    <Badge>
                      {originDoc.countryName}
                    </Badge>
                  ) : null}

                  {originDoc.continentName ? (
                    <Badge>
                      {originDoc.continentName}
                    </Badge>
                  ) : null}
                </Wrap>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}

export default function InfoCell(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <InfoCellMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
