import { Avatar, AvatarBadge, Icon } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { isProfileComplete, ProfileDoc } from '../../collections/Profiles';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import Catch from '../../components/Catch';
import useCurrentTime from '../../hooks/useCurrentTime';

export type Props = {
  participantRef: DocumentReference<ProfileDoc>;
};

export function ParticipantAvatarMain({ participantRef }: Props) {
  const { data: participantSnap } = useFirestoreDoc(participantRef);

  if (!participantSnap.exists()) {
    throw new Error('Participant does not exist');
  }

  const participantDoc = useMemo(() => participantSnap.data(), [participantSnap]);

  if (!isProfileComplete(participantDoc)) {
    throw new Error('Profile is not complete');
  }

  const currentTime = useCurrentTime();

  const primaryPictureSrc = useMemo(
    () => {
      const uri = participantDoc.pictures[participantDoc.primaryPictureIndex ?? 0]?.imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 40,
        uri,
        width: 40,
      });
    },
    [participantDoc.pictures, participantDoc.primaryPictureIndex],
  );

  return (
    <Avatar
      as={Link}
      boxSize={10}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      size="md"
      src={primaryPictureSrc}
      to="participants"
    >
      {
        currentTime.getTime() - 1000 * 60 < (participantDoc.lastSeenAt?.toMillis() ?? 0)
          ? (
            <AvatarBadge bg="green.500" border="none" boxSize={3} transform="none" />
          )
          : null
      }
    </Avatar>
  );
}

export default function ParticipantAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ParticipantAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
