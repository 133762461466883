import { Button, Icon, Wrap } from '@chakra-ui/react';
import { deleteField, serverTimestamp, writeBatch } from 'firebase/firestore';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestore } from 'reactfire';

import { getProfileHiddenRef } from '../../../collections/Profiles';
import Gender from '../../../common/Gender';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import TripBudget from '../../../common/TripBudget';
import Catch from '../../../components/Catch';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';
import { useProfileSnap } from '../../../components/snapProviders/ProfileSnapProvider';
import useShowError from '../../../hooks/useShowError';

export function AdminMain() {
  const showError = useShowError();
  const myProfileSnap = useMyProfileSnap();

  const profileSnap = useProfileSnap();

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const profileHiddenRef = useMemo(
    () => getProfileHiddenRef(profileSnap.ref),
    [profileSnap.ref],
  );

  const firestore = useFirestore();

  const [isLoading, setLoading] = useState<boolean>(false);

  const handlePromoteArtemis = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const batch = writeBatch(firestore);

    batch.set(profileSnap.ref, {
      extraScore: deleteField(),
      tier: Tier.ARTEMIS,
      updatedAt: serverTimestamp(),
    }, { merge: true });

    batch.set(profileHiddenRef, {
      maxTripBudget: TripBudget.STANDARD,
    }, { merge: true });

    setLoading(true);
    batch
      .commit()
      .finally(() => setLoading(false))
      .catch(showError);
  }, [firestore, profileSnap.ref, profileHiddenRef, showError]);

  const handlePromoteAthena = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const batch = writeBatch(firestore);

    batch.set(profileSnap.ref, {
      extraScore: 50,
      tier: Tier.ATHENA,
      updatedAt: serverTimestamp(),
    }, { merge: true });

    batch.set(profileHiddenRef, {
      maxTripBudget: TripBudget.PREMIUM,
    }, { merge: true });

    setLoading(true);
    batch
      .commit()
      .finally(() => setLoading(false))
      .catch(showError);
  }, [firestore, profileSnap.ref, profileHiddenRef, showError]);

  const handlePromoteAphrodite = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const batch = writeBatch(firestore);

    batch.set(profileSnap.ref, {
      extraScore: 100,
      tier: Tier.APHRODITE,
      updatedAt: serverTimestamp(),
    }, { merge: true });

    batch.set(profileHiddenRef, {
      maxTripBudget: TripBudget.LUXURY,
    }, { merge: true });

    setLoading(true);
    batch
      .commit()
      .finally(() => setLoading(false))
      .catch(showError);
  }, [firestore, profileSnap.ref, profileHiddenRef, showError]);

  const handlePromoteHera = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const batch = writeBatch(firestore);

    batch.set(profileSnap.ref, {
      extraScore: 200,
      tier: Tier.HERA,
      updatedAt: serverTimestamp(),
    }, { merge: true });

    batch.set(profileHiddenRef, {
      maxTripBudget: TripBudget.ULTIMATE,
    }, { merge: true });

    setLoading(true);
    batch
      .commit()
      .finally(() => setLoading(false))
      .catch(showError);
  }, [firestore, profileSnap.ref, profileHiddenRef, showError]);

  if (myProfileSnap.ref.id !== '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1') {
    return null;
  }

  return (
    <Wrap>
      <Button
        isActive={profileDoc.tier === Tier.ARTEMIS}
        isDisabled={profileDoc.gender !== Gender.FEMALE}
        isLoading={isLoading}
        leftIcon={<Icon as={TierMap[Tier.ARTEMIS].icon} color={`${TierMap[Tier.ARTEMIS].colorScheme}.200`} />}
        onClick={handlePromoteArtemis}
        size="sm"
      >
        Artemis
      </Button>

      <Button
        isActive={profileDoc.tier === Tier.ATHENA}
        isDisabled={profileDoc.gender !== Gender.FEMALE}
        isLoading={isLoading}
        leftIcon={<Icon as={TierMap[Tier.ATHENA].icon} color={`${TierMap[Tier.ATHENA].colorScheme}.200`} />}
        onClick={handlePromoteAthena}
        size="sm"
      >
        Athena
      </Button>

      <Button
        isActive={profileDoc.tier === Tier.APHRODITE}
        isDisabled={profileDoc.gender !== Gender.FEMALE}
        isLoading={isLoading}
        leftIcon={<Icon as={TierMap[Tier.APHRODITE].icon} color={`${TierMap[Tier.APHRODITE].colorScheme}.200`} />}
        onClick={handlePromoteAphrodite}
        size="sm"
      >
        Aphrodite
      </Button>

      <Button
        isActive={profileDoc.tier === Tier.HERA}
        isDisabled={profileDoc.gender !== Gender.FEMALE}
        isLoading={isLoading}
        leftIcon={<Icon as={TierMap[Tier.HERA].icon} color={`${TierMap[Tier.HERA].colorScheme}.200`} />}
        onClick={handlePromoteHera}
        size="sm"
      >
        Hera
      </Button>
    </Wrap>
  );
}

export default function Admin() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <AdminMain />
      </Suspense>
    </Catch>
  );
}
