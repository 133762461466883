import { Center } from '@chakra-ui/react';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import LogoIcon from './components/LogoIcon';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
      <App />
    </Suspense>
  </StrictMode>,
);
