import {
  Box,
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreCollection, useUser } from 'reactfire';

import { isProfileComplete } from '../../collections/Profiles';
import { useTelegramUsersCollectionRef } from '../../collections/TelegramUsers';
import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Applications from './Applications';
import ChatsButton from './ChatsButton';
import ExploreButton from './ExploreButton';
import Login from './Login';
import PlansButton from './PlansButton';
import Profile from './Profile';
import ProfileButton from './ProfileButton';
import Telegram from './Telegram';
import WishlistButton from './WishlistButton';

export function MainLayoutMain() {
  const insets = useInsets();

  const { data: user } = useUser();

  const showLogin = useMemo<boolean>(() => !user || user.isAnonymous, [user]);

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();

  const myProfileSnap = useMyProfileSnap();

  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const { data: telegramUsersSnap } = useFirestoreCollection(
    query(
      telegramUsersCollectionRef,
      where('uid', '==', myProfileSnap.ref.id),
      limit(1),
    ),
  );

  const isComplete = useMemo(() => isProfileComplete(myProfileDoc), [myProfileDoc]);
  const [initialIsComplete] = useState(isComplete);

  const showProfile = useMemo<boolean>(
    () => !myProfileDoc || !isComplete,
    [myProfileDoc, isComplete],
  );

  useEffect(
    () => {
      if (initialIsComplete !== isComplete && isComplete) {
        mixpanel.track('Onboarding Profile Step Completed');
      }
    },
    [initialIsComplete, isComplete],
  );

  const showTelegram = useMemo<boolean>(() => telegramUsersSnap.empty, [telegramUsersSnap.empty]);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      {(showLogin || showProfile || showTelegram) && (
        <Container
          flexShrink={0}
          maxW="lg"
          pb={2}
          pt={`max(${insets.top}, var(--chakra-space-2))`}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {showLogin ? <Login /> : (
            // eslint-disable-next-line no-nested-ternary
            showProfile ? <Profile /> : (
              showTelegram ? <Telegram /> : null
            )
          )}
        </Container>
      )}

      <Box flex={1} minH={0} overflow="hidden">
        <InsetsProvider
          bottom="0px"
          left={insets.left}
          right={insets.right}
          top={(showLogin || showProfile || showTelegram) ? '0px' : insets.top}
        >
          <Outlet />
        </InsetsProvider>
      </Box>

      <Container
        flexShrink={0}
        maxW="lg"
        pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        pt={2}
      >
        <HStack gap={2} justifyContent="space-between">
          <WishlistButton />
          <PlansButton />
          <Applications />
          <ExploreButton />
          <ChatsButton />
          <ProfileButton />
        </HStack>
      </Container>
    </VStack>
  );
}

export default function MainLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MainLayoutMain />
      </Suspense>
    </Catch>
  );
}
