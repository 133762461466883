import {
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiLogoTelegram } from 'react-icons/bi';

import useTelegramAuthWebApp from '../../../functions/useTelegramAuthWebApp';
import useTelegramGetLoginToken from '../../../functions/useTelegramGetLoginToken';

export default function Login() {
  const { t } = useTranslation('MainLayout', { keyPrefix: 'Onboarding.Telegram' });

  const [token, setToken] = useState<string>();

  const telegramGetLoginToken = useTelegramGetLoginToken();
  const telegramAuthWebApp = useTelegramAuthWebApp();

  useEffect(
    () => {
      if (Telegram.WebApp.initData !== '') {
        telegramAuthWebApp({ initData: Telegram.WebApp.initData })
          .then(() => mixpanel.track('Onboarding Telegram Step Completed'))
          .catch(() => { });
      } else {
        telegramGetLoginToken()
          .then(({ data }) => setToken(data.token))
          .then(() => mixpanel.track('Onboarding Telegram Step Completed'))
          .catch(() => { });
      }
    },
    [telegramAuthWebApp, telegramGetLoginToken],
  );

  return (
    <VStack alignItems="stretch" gap={1}>
      <Text
        fontSize="xs"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {t('title')}
      </Text>

      <HStack gap={2}>
        <Text
          flex={1}
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {t('description')}
        </Text>

        <Button
          as="a"
          flexShrink={0}
          href={token ? `https://t.me/qupidubot?start=${token}` : undefined}
          isLoading={!token}
          leftIcon={<Icon as={BiLogoTelegram} />}
          size="sm"
          target="_blanc"
        >
          {t('connectButton.default')}
        </Button>
      </HStack>
    </VStack>
  );
}
