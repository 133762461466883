import {
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuUser } from 'react-icons/lu';
import { Link } from 'react-router-dom';

export default function Profile() {
  const { t } = useTranslation('MainLayout', { keyPrefix: 'Onboarding.Profile' });

  return (
    <VStack alignItems="stretch" gap={1}>
      <Text
        fontSize="xs"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {t('title')}
      </Text>

      <HStack gap={2}>
        <Text
          flex={1}
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {t('description')}
        </Text>

        <Button
          as={Link}
          flexShrink={0}
          leftIcon={<Icon as={LuUser} />}
          size="sm"
          to="/profile/update"
        >
          {t('connectButton.default')}
        </Button>
      </HStack>
    </VStack>
  );
}
