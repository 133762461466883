import {
  Box,
  HStack,
  ListItem,
  Radio,
  Text,
  UnorderedList,
  useRadio,
  UseRadioProps,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TripBudget from '../../common/TripBudget';
import TripBudgetMap from '../../common/TripBudgetMap';

export type Props = UseRadioProps;

export default function UltimateCard(props: Props) {
  const { t } = useTranslation('BudgetFormControl');

  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1} userSelect="none">
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
      />
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...checkbox}
        _dark={{
          _checked: {
            backgroundColor: `rgb(from var(--chakra-colors-${TripBudgetMap[TripBudget.ULTIMATE].colorScheme}-200) r g b / 0.16)`,
            color: `var(--chakra-colors-${TripBudgetMap[TripBudget.ULTIMATE].colorScheme}-200)`,
          },
          _disabled: {
            backgroundColor: 'rgb(from var(--chakra-colors-gray-600) r g b / 0.16)',
            color: 'var(--chakra-colors-gray-600)',
          },
          backgroundColor: 'rgb(from var(--chakra-colors-gray-200) r g b / 0.16)',
          color: 'var(--chakra-colors-gray-200)',
        }}
        _disabled={{
          cursor: 'not-allowed',
        }}
        _light={{
          _checked: {
            backgroundColor: `var(--chakra-colors-${TripBudgetMap[TripBudget.ULTIMATE].colorScheme}-100)`,
            color: `var(--chakra-colors-${TripBudgetMap[TripBudget.ULTIMATE].colorScheme}-800)`,
          },
          _disabled: {
            backgroundColor: 'var(--chakra-colors-gray-100)',
            color: 'var(--chakra-colors-gray-500)',
          },
          backgroundColor: 'var(--chakra-colors-gray-100)',
          color: 'var(--chakra-colors-gray-800)',
        }}
        borderRadius="md"
        cursor="pointer"
        px={5}
        py={3}
      >
        <VStack alignItems="stretch" height="100%">
          <HStack alignItems="center">
            <Radio
              colorScheme={TripBudgetMap[TripBudget.ULTIMATE].colorScheme}
              // eslint-disable-next-line react/destructuring-assignment
              isChecked={props.isChecked}
            />

            <Text flex={1} fontSize="xl" fontWeight="bold">
              {t('ultimateCard.title')}
            </Text>
          </HStack>

          <Text fontSize="sm">
            {t('ultimateCard.body')}
          </Text>

          <UnorderedList fontSize="xs">
            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('accommodations')}
                :
              </Text>
              {' '}
              {t('ultimateCard.accommodations')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('transportation')}
                :
              </Text>
              {' '}
              {t('ultimateCard.transportation')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('travel')}
                :
              </Text>
              {' '}
              {t('ultimateCard.travel')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('food')}
                :
              </Text>
              {' '}
              {t('ultimateCard.food')}
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                {t('activities')}
                :
              </Text>
              {' '}
              {t('ultimateCard.activities')}
            </ListItem>
          </UnorderedList>
        </VStack>
      </Box>
    </Box>
  );
}
