/* eslint-disable perfectionist/sort-enums */
enum Tier {
  MORTAL = 'MORTAL',
  HERMES = 'HERMES',
  DIONYSUS = 'DIONYSUS',
  POSEIDON = 'POSEIDON',
  ZEUS = 'ZEUS',
  ARTEMIS = 'ARTEMIS',
  ATHENA = 'ATHENA',
  APHRODITE = 'APHRODITE',
  HERA = 'HERA',
}

export default Tier;
