import {
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import BillingPortalButton from './BillingPortalButton';
import ColorModeSelector from './ColorModeSelector';
import LanguageSelector from './LanguageSelector';
import LogOutButton from './LogOutButton';
import NotificationsButton from './NotificationsButton';
import ProfileStatusButton from './ProfileStatusButton';
import SupportButton from './SupportButton';
import TelegramUsers from './TelegramUsers';

export function SettingsScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'SettingsScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('SettingsScreen');

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <IconButton
            aria-label={t('backButton.default')}
            as={Link}
            icon={<Icon as={LuChevronLeft} />}
            to=".."
          />

          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        overflowY="auto"
        py={2}
      >
        <VStack alignItems="stretch" gap={8}>
          <BillingPortalButton />
          <SupportButton />
          <LanguageSelector />
          <ColorModeSelector />
          <TelegramUsers />
          <NotificationsButton />
          <ProfileStatusButton />
          <LogOutButton />
        </VStack>
      </Container>
    </VStack>
  );
}

export default function SettingsScreen() {
  const { t } = useTranslation('SettingsScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <SettingsScreenMain />
      </Suspense>
    </Catch>
  );
}
