import {
  Box,
  BoxProps,
  HStack,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Gender from '../../../common/Gender';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';

export type Props = BoxProps;

enum CardType {
  SUBSCRIBE,
  UPGRADE,
  CURRENT,
  DOWNGRADE,
}

export default function MortalButton(boxProps: Props) {
  const { t } = useTranslation('MyProfileScreen', { keyPrefix: 'Subscription' });

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const cardType = useMemo<CardType>(() => {
    switch (myProfileDoc.tier) {
      case Tier.HERMES:
      case Tier.DIONYSUS:
      case Tier.POSEIDON:
      case Tier.ZEUS:
        return CardType.DOWNGRADE;

      default:
        return CardType.CURRENT;
    }
  }, [myProfileDoc]);

  if (cardType === CardType.DOWNGRADE) {
    return null;
  }

  if (myProfileDoc.gender !== Gender.MALE) {
    return null;
  }

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      _dark={{
        backgroundColor: `rgb(from var(--chakra-colors-${TierMap[Tier.MORTAL].colorScheme}-200) r g b / 0.16)`,
        color: `var(--chakra-colors-${TierMap[Tier.MORTAL].colorScheme}-200)`,
      }}
      _light={{
        backgroundColor: `var(--chakra-colors-${TierMap[Tier.MORTAL].colorScheme}-100)`,
        color: `var(--chakra-colors-${TierMap[Tier.MORTAL].colorScheme}-800)`,
      }}
      borderRadius="md"
      px={5}
      py={3}
      userSelect="none"
    >
      <VStack alignItems="stretch" height="100%">
        <HStack>
          <Icon as={TierMap[Tier.MORTAL].icon} boxSize={6} />

          <Text flex={1} fontSize="xl" fontWeight="bold">
            {t('mortalButton.title')}
          </Text>

          <Text fontSize="xl" fontWeight="bold">
            {cardType === CardType.CURRENT ? t('active') : 'Free'}
          </Text>
        </HStack>

        <Text fontSize="xs">
          {t('mortalButton.body')}
        </Text>

        <UnorderedList fontSize="xs">
          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('badge')}
              :
            </Text>
            {' '}
            {t('mortalButton.badge')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('baseScore')}
              :
            </Text>
            {' '}
            {t('mortalButton.baseScore')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('tripBudget')}
              :
            </Text>
            {' '}
            {t('mortalButton.tripBudget')}
          </ListItem>
        </UnorderedList>
      </VStack>
    </Box>
  );
}
