import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import TripBudget from '../../common/TripBudget';
import useShowError from '../../hooks/useShowError';
import BudgetRadioGroup from './BudgetRadioGroup';

export interface Props {
  label: string;
  name: string;
}

export default function BudgetFormControl({
  label,
  name,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<TripBudget>(name);

  const handleChange = useCallback(
    (value: TripBudget) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <BudgetRadioGroup name={name} onChange={handleChange} value={input.value} />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
