import {
  Badge,
  Box,
  BoxProps,
  Collapse,
  Container,
  Divider,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { LuLanguages, LuSparkles } from 'react-icons/lu';
import { PiGenderFemaleBold, PiGenderIntersexBold, PiGenderMaleBold } from 'react-icons/pi';

import { useProfilesCollectionRef } from '../../../../collections/Profiles';
import AppLanguage from '../../../../common/AppLanguage';
import Gender from '../../../../common/Gender';
import Sexuality from '../../../../common/Sexuality';
import Tier from '../../../../common/Tier';
import TierMap from '../../../../common/TierMap';
import TripAlgoliaSearchRecord from '../../../../common/TripAlgoliaSearchRecord';
import TripBudget from '../../../../common/TripBudget';
import { useAlgoliaInsightsClient } from '../../../../components/AlgoliaInsightsClientProvider';
import { useInsets } from '../../../../components/InsetsProvider';
import { useMyProfileSnap } from '../../../../components/snapProviders/MyProfileSnapProvider';
import TripBudgetBadge from '../../../../components/TripBudgetBadge';
import ActionCell from './ActionCell';
import EconomyCard from './EconomyCard';
import LuxuryCard from './LuxuryCard';
import OrganizerAvatar from './OrganizerAvatar';
import PremiumCard from './PremiumCard';
import StandardCard from './StandardCard';
import UltimateCard from './UltimateCard';

export type Props = {
  queryId: string | undefined;
  searchResultPosition: number;
  tripRecord: TripAlgoliaSearchRecord;
} & BoxProps;

export default function InfoCell({
  queryId,
  searchResultPosition,
  tripRecord,
  ...boxProps
}: Props) {
  const insets = useInsets();

  const algoliaInsights = useAlgoliaInsightsClient();

  const { i18n, t } = useTranslation('TripsScreen', { keyPrefix: 'Trip.InfoCell' });
  const { t: lt } = useTranslation('Languages');
  const { t: tt } = useTranslation('Tier');
  const { t: gt } = useTranslation('Gender');
  const { t: st } = useTranslation('Sexuality');
  const { t: tdt } = useTranslation('TripDuration');
  const { t: zt } = useTranslation('Zodiac');

  const { isOpen: isOpenDetails, onToggle: onToggleDetails } = useDisclosure();

  useEffect(
    () => {
      mixpanel.track(isOpenDetails ? 'Trip Details Opened' : 'Trip Details Closed', {
        budget: tripRecord.budget,
        createdAt: new Date(tripRecord.createdAt),
        departure: tripRecord.departure,
        destinationCountryId: tripRecord.destinationCountryId,
        destinationId: tripRecord.destinationId,
        duration: tripRecord.duration,
        organizerAge: tripRecord.organizer.age,
        organizerGender: tripRecord.organizer.gender,
        organizerHeight: tripRecord.organizer.height,
        organizerId: tripRecord.organizer.id,
        organizerLanguages: tripRecord.organizer.languages,
        organizerName: tripRecord.organizer.name,
        organizerOriginCityId: tripRecord.organizer.origin.city.id,
        organizerOriginCountryId: tripRecord.organizer.origin.country?.id,
        organizerScore: tripRecord.organizer.score,
        organizerSexuality: tripRecord.organizer.sexuality,
        organizerSexualityGender: tripRecord.organizer.sexualityGender,
        organizerTier: tripRecord.organizer.tier,
        organizerWeight: tripRecord.organizer.weight,
        organizerZodiac: tripRecord.organizer.zodiac,
        tripId: tripRecord.objectID,
      });
    },
    [
      isOpenDetails,
      tripRecord,
    ],
  );

  const myProfileSnap = useMyProfileSnap();
  const handleClick = useCallback(
    () => {
      onToggleDetails();

      if (queryId) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: myProfileSnap.ref.id,
              eventName: 'Trip Details Clicked',
              eventType: 'click',
              index: 'trips',
              objectIDs: [tripRecord.objectID],
              positions: [searchResultPosition + 1],
              queryID: queryId,
              timestamp: Date.now(),
              userToken: myProfileSnap.ref.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [
      onToggleDetails,
      queryId,
      algoliaInsights,
      myProfileSnap.ref.id,
      tripRecord.objectID,
      searchResultPosition,
    ],
  );

  const profilesCollectionRef = useProfilesCollectionRef();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <Container maxW="lg">
        <Box
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          borderRadius="2xl"
          className="tripInfo"
          cursor="pointer"
          onClick={handleClick}
          p={4}
        >
          <Collapse
            in={isOpenDetails}
            startingHeight="48px"
          >
            <VStack alignItems="stretch" gap={4}>
              <HStack gap={4}>
                <OrganizerAvatar
                  organizerRef={doc(profilesCollectionRef, tripRecord.organizer.id)}
                />

                <VStack alignItems="stretch" flex={1} gap={1} minW={0}>
                  <HStack>
                    <Text
                      flexShrink={1}
                      fontSize="md"
                      lineHeight={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {/* eslint-disable-next-line max-len */}
                      {tripRecord.organizer.translations?.name[i18n.language as AppLanguage] ?? tripRecord.organizer.name}
                      {', '}
                      {tripRecord.organizer.age}
                    </Text>

                    <TripBudgetBadge tripBudget={tripRecord.budget} />

                    {tripRecord.organizer.instagramTag && (
                      <Icon as={FaInstagram} boxSize={4} />
                    )}

                    {tripRecord.organizer.tiktokTag && (
                      <Icon as={FaTiktok} boxSize={4} />
                    )}

                    {tripRecord.organizer.linkedinTag && (
                      <Icon as={FaLinkedin} boxSize={4} />
                    )}
                  </HStack>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {_.compact([
                      tripRecord.destinationCountryEmoji,
                      _.compact([
                        tripRecord.destinationName[i18n.language as AppLanguage],
                        tripRecord.destinationCountryName[i18n.language as AppLanguage],
                      ]).join(', '),
                    ]).join(' ')}
                  </Text>

                  <Text fontSize="xs" lineHeight={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {t('departure', {
                      departure: moment(tripRecord.departure).toDate(),
                      formatParams: { departure: { day: 'numeric', month: 'long' } },
                    })}
                  </Text>
                </VStack>

                <ActionCell
                  queryId={queryId}
                  tripRecord={tripRecord}
                />
              </HStack>

              <Divider />

              <VStack
                alignItems="stretch"
                gap={4}
                overflow="auto"
                style={{
                  maxHeight: `calc(100vh - (${insets.top} + var(--chakra-space-4)) - (${insets.bottom} + var(--chakra-space-2)) - var(--chakra-space-12) - var(--chakra-space-16) - var(--chakra-space-1))`,
                }}
              >
                {tripRecord.budget === TripBudget.ECONOMY ? (
                  <EconomyCard />
                ) : null}

                {tripRecord.budget === TripBudget.STANDARD ? (
                  <StandardCard />
                ) : null}

                {tripRecord.budget === TripBudget.PREMIUM ? (
                  <PremiumCard />
                ) : null}

                {tripRecord.budget === TripBudget.LUXURY ? (
                  <LuxuryCard />
                ) : null}

                {tripRecord.budget === TripBudget.ULTIMATE ? (
                  <UltimateCard />
                ) : null}

                <Divider />

                <Wrap>
                  <Badge>
                    {tdt(tripRecord.duration)}
                  </Badge>

                  <Badge>
                    {tripRecord.destinationName[i18n.language as AppLanguage]}
                  </Badge>

                  <Badge>
                    {tripRecord.destinationCountryName[i18n.language as AppLanguage]}
                  </Badge>

                  {tripRecord.destination.continent?.name ? (
                    <Badge>
                      {tripRecord.destination.continent?.name}
                    </Badge>
                  ) : null}

                  {(tripRecord.activities ?? []).map((activity) => (
                    <Badge key={activity}>{activity}</Badge>
                  ))}
                </Wrap>

                <Divider />

                <Wrap>
                  {tripRecord.organizer.tier === Tier.MORTAL ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.MORTAL].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.MORTAL].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.HERMES ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERMES].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERMES].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.DIONYSUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.DIONYSUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.DIONYSUS].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.POSEIDON ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.POSEIDON].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.POSEIDON].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.ZEUS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ZEUS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ZEUS].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.ARTEMIS ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ARTEMIS].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ARTEMIS].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.ATHENA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.ATHENA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.ATHENA].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.APHRODITE ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.APHRODITE].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.APHRODITE].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.tier === Tier.HERA ? (
                    <Badge alignItems="center" colorScheme={TierMap[Tier.HERA].colorScheme} display="flex" gap={1}>
                      <Icon as={TierMap[Tier.HERA].icon} boxSize={3} />
                      {tt(`${tripRecord.organizer.tier}.longLabel`)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.gender === Gender.FEMALE ? (
                    <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                      <Icon as={PiGenderFemaleBold} boxSize={3} />
                      {gt(tripRecord.organizer.gender)}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.gender === Gender.MALE ? (
                    <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                      <Icon as={PiGenderMaleBold} boxSize={3} />
                      {gt(tripRecord.organizer.gender)}
                    </Badge>
                  ) : null}

                  {
                    tripRecord.organizer.sexuality === Sexuality.STRAIGHT
                      && tripRecord.organizer.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.STRAIGHT
                      && tripRecord.organizer.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.GAY
                      && tripRecord.organizer.gender === Gender.FEMALE ? (
                        <Badge alignItems="center" colorScheme="pink" display="flex" gap={1}>
                          <Icon as={PiGenderFemaleBold} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.GAY
                      && tripRecord.organizer.gender === Gender.MALE ? (
                        <Badge alignItems="center" colorScheme="blue" display="flex" gap={1}>
                          <Icon as={PiGenderMaleBold} size={5} />
                          {st(tripRecord.organizer.sexuality)}
                        </Badge>
                      ) : null
                  }

                  {
                    tripRecord.organizer.sexuality === Sexuality.BISEXUAL ? (
                      <Badge alignItems="center" colorScheme="purple" display="flex" gap={1}>
                        <Icon as={PiGenderIntersexBold} size={5} />
                        {st(tripRecord.organizer.sexuality)}
                      </Badge>
                    ) : null
                  }

                  {tripRecord.organizer.instagramTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.instagram.com/${tripRecord.organizer.instagramTag}`}
                      target="_blank"
                    >
                      <Icon as={FaInstagram} size={5} />
                      {tripRecord.organizer.instagramTag}
                    </Badge>
                  )}

                  {tripRecord.organizer.tiktokTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.tiktok.com/@${tripRecord.organizer.tiktokTag}`}
                      target="_blank"
                    >
                      <Icon as={FaTiktok} size={5} />
                      {tripRecord.organizer.tiktokTag}
                    </Badge>
                  )}

                  {tripRecord.organizer.linkedinTag && (
                    <Badge
                      alignItems="center"
                      as="a"
                      display="flex"
                      gap={1}
                      href={`https://www.linkedin.com/in/${tripRecord.organizer.linkedinTag}`}
                      target="_blank"
                    >
                      <Icon as={FaLinkedin} size={5} />
                      {tripRecord.organizer.linkedinTag}
                    </Badge>
                  )}

                  {tripRecord.organizer.languages.map((l) => (
                    <Badge alignItems="center" display="flex" gap={1} key={l}>
                      <Icon as={LuLanguages} size={5} />
                      {lt(l)}
                    </Badge>
                  ))}

                  <Badge>
                    {tripRecord.organizer.height}
                    cm
                  </Badge>

                  <Badge>
                    {tripRecord.organizer.weight}
                    kg
                  </Badge>

                  <Badge>
                    {tripRecord.organizer.age}
                    y
                  </Badge>

                  <Badge alignItems="center" display="flex" gap={1}>
                    <Icon as={LuSparkles} size={5} />
                    {zt(tripRecord.organizer.zodiac)}
                  </Badge>

                  {tripRecord.organizer.origin.city.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.city.name}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.origin.country?.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.country?.name}
                    </Badge>
                  ) : null}

                  {tripRecord.organizer.origin.continent?.name ? (
                    <Badge>
                      {tripRecord.organizer.origin.continent?.name}
                    </Badge>
                  ) : null}
                </Wrap>

                <Divider />

                <Text>
                  {/* eslint-disable-next-line max-len */}
                  {tripRecord.translations?.description[i18n.language as AppLanguage] ?? tripRecord.description}
                </Text>
              </VStack>
            </VStack>
          </Collapse>
        </Box>
      </Container>
    </Box>
  );
}
