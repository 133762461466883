import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BannedAlertContext = createContext<(() => void) | null>(null);

export const useBannedAlert = () => {
  const ctx = useContext(BannedAlertContext);

  if (!ctx) {
    throw new Error('BannedAlertProvider');
  }

  return ctx;
};

export default function BannedAlertProvider({
  children,
}: PropsWithChildren) {
  const { t } = useTranslation('BannedAlert');

  const { isOpen, onClose, onOpen } = useDisclosure();

  const navigate = useNavigate();

  const handleConfirm = useCallback(
    () => {
      onClose();
      navigate('/profile/update');
    },
    [navigate, onClose],
  );

  return (
    <BannedAlertContext.Provider value={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay
          backdropFilter="saturate(180%) blur(20px)"
          backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
        />

        <ModalContent mx={4}>
          <ModalCloseButton />

          <ModalHeader>
            {t('title')}
          </ModalHeader>

          <ModalBody>
            <Text>
              {t('body')}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              {t('closeButton.default')}
            </Button>

            <Button onClick={handleConfirm}>
              {t('complyButton.default')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {children}
    </BannedAlertContext.Provider>
  );
}
