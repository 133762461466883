import {
  Box,
  Center,
  Container,
  Text,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { DocumentReference } from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationDoc } from '../../collections/Destinations';
import DestinationAlgoliaSearchRecord from '../../common/DestinationAlgoliaSearchRecord';
import { useAlgoliaInsightsClient } from '../../components/AlgoliaInsightsClientProvider';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Destination from './Destination';

export type Props = {
  height: number;
  onNextClick: (destinationRef: DocumentReference<DestinationDoc>) => unknown;
  query: string;
  width: number;
};

export function DestinationListMain({
  height,
  onNextClick,
  query,
  width,
}: Props) {
  const { t } = useTranslation('TripCreateInitScreen', { keyPrefix: 'DestinationList' });
  const showError = useShowError();

  const client = useAlgoliaSearchClient();
  const algoliaInsights = useAlgoliaInsightsClient();
  const destinationsIndex = useMemo(() => client.initIndex('destinations'), [client]);

  const myProfileSnap = useMyProfileSnap();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [destinations, setDestinations] = useState<DestinationAlgoliaSearchRecord[]>([]);
  const [queryId, setQueryId] = useState<string | undefined>(undefined);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      setIsLoading(true);
      destinationsIndex.search<DestinationAlgoliaSearchRecord>(query, {
        clickAnalytics: true,
        // enablePersonalization: true,
        length: 1000,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setDestinations(response.hits);
        setQueryId(response.queryID);
        ref.current?.scrollTo({ top: 0 });
      }).catch(showError);
    },
    [myProfileSnap.ref.id, showError, query, destinationsIndex],
  );

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(destinations.length, (i) => ({
    borderRadius: 0,
    display: 'block',
    scale: 1,
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        destinations.length - 1,
      );
      setCurrentIndex(animationIndex.current);
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          display: 'none',
        };
      }

      const borderRadius = active
        ? (clamp(
          Math.abs(mx),
          0,
          100,
        ) / 100) * 32
        : 0;
      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return {
        borderRadius, display: 'block', scale, x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 10,
  });

  useEffect(
    () => {
      if (destinations[currentIndex]) {
        mixpanel.track('Destinations List Scrolled', {
          index: currentIndex,
          profileId: destinations[currentIndex].objectID,
        });
      }
    },
    [currentIndex, destinations],
  );

  useEffect(
    () => {
      if (destinations[currentIndex]) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: myProfileSnap.ref.id,
              eventName: 'Destination View',
              eventType: 'view',
              index: 'destinations',
              objectIDs: [destinations[currentIndex].objectID],
              timestamp: Date.now(),
              userToken: myProfileSnap.ref.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [currentIndex, myProfileSnap.ref.id, destinations, algoliaInsights],
  );

  if (destinations.length) {
    return (
      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        ref={ref}
        w={`${width}px`}
      >
        {pages.map(({
          borderRadius, display, scale, x,
        }, searchResultPosition) => (
          Math.abs(currentIndex - searchResultPosition) > 5
            ? null
            : (
              <Box
                as={animated.div}
                h={`${height}px`}
                key={destinations[searchResultPosition].objectID}
                position="absolute"
                w={`${width}px`}
            // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                style={{
                  display,
                  touchAction: 'none',
                  x,
                }}
              >
                <Destination
                  as={animated.div}
                  destinationRecord={destinations[searchResultPosition]}
                  height={height}
                  onNextClick={onNextClick}
                  overflow="hidden"
                  queryId={queryId}
                  searchResultPosition={searchResultPosition}
                  style={{
                    borderRadius: borderRadius as unknown as number,
                    scale: scale as unknown as number,
                    touchAction: 'none',
                  }}
                  width={width}
                />
              </Box>
            )
        ))}
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function DestinationList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <DestinationListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
