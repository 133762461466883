import {
  Box,
  Center,
  Container,
  Text,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { DocumentReference } from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileDoc } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useConversationSnap } from '../../components/snapProviders/ConversationSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Profile from './Profile';

export type Props = {
  height: number;
  profileRefs: DocumentReference<ProfileDoc>[];
  width: number;
};

export function ProfileListMain({ height, profileRefs, width }: Props) {
  const { t } = useTranslation('ConversationParticipantsScreen', { keyPrefix: 'ProfileList' });

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(profileRefs.length, (i) => ({
    borderRadius: 0,
    display: 'block',
    scale: 1,
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        profileRefs.length - 1,
      );
      setCurrentIndex(animationIndex.current);
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          display: 'none',
        };
      }

      const borderRadius = active
        ? (clamp(
          Math.abs(mx),
          0,
          100,
        ) / 100) * 32
        : 0;
      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return {
        borderRadius, display: 'block', scale, x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 10,
  });

  const conversationSnap = useConversationSnap();

  useEffect(
    () => {
      if (profileRefs[currentIndex]) {
        mixpanel.track('Conversation Participant List Scrolled', {
          conversationId: conversationSnap.id,
          index: currentIndex,
          profileId: profileRefs[currentIndex].id,
        });
      }
    },
    [conversationSnap.id, currentIndex, profileRefs],
  );

  if (profileRefs.length) {
    return (
      <Box
        h={`${height}px`}
        overflow="hidden"
        position="relative"
        w={`${width}px`}
      >
        {pages.map(({
          borderRadius, display, scale, x,
        }, searchResultPosition) => (
          Math.abs(currentIndex - searchResultPosition) > 5
            ? null
            : (
              <Box
                as={animated.div}
                h={`${height}px`}
                key={profileRefs[searchResultPosition].id}
                position="absolute"
                w={`${width}px`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bind()}
                style={{
                  display,
                  touchAction: 'none',
                  x,
                }}
              >
                <Profile
                  as={animated.div}
                  height={height}
                  overflow="hidden"
                  profileRef={profileRefs[searchResultPosition]}
                  style={{
                    borderRadius: borderRadius as unknown as number,
                    scale: scale as unknown as number,
                    touchAction: 'none',
                  }}
                  width={width}
                />
              </Box>
            )
        ))}
      </Box>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function ProfileList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
