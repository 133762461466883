import { useMemo } from 'react';

import Gender from '../../common/Gender';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ProfilesScreen from './ProfilesScreen';
import TripsScreen from './TripsScreen';

export default function HomeScreen() {
  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  if (myProfileDoc?.gender === Gender.FEMALE) {
    return (
      <TripsScreen />
    );
  }

  return (
    <ProfilesScreen />
  );
}
