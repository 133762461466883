import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import AppLanguage from '../common/AppLanguage';
import TripBudget from '../common/TripBudget';
import { UnsplashPicture } from '../common/UnsplashPicture';
import { type DestinationDoc } from './Destinations';
import { type ProfileDoc } from './Profiles';

export enum TripStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum TripDuration {
  MONTH = 'MONTH',
  SEASON = 'SEASON',
  WEEK = 'WEEK',
  WEEKEND = 'WEEKEND',
}

export interface TripDoc {
  _v: 1;
  activities: string[];
  budget: TripBudget;
  createdAt: Timestamp;
  departure: string;
  description: string;
  destinationRef: DocumentReference<DestinationDoc>;
  duration: TripDuration;
  expiresAt: Timestamp;
  indexedAt?: Timestamp;
  organizerRef: DocumentReference<ProfileDoc>;
  picture: UnsplashPicture;
  status: TripStatus;
  translations?: {
    description: Record<AppLanguage, string>;
  };
  updatedAt: Timestamp;
}

export const isTripDoc = (
  data?: DocumentData,
): data is TripDoc => true;

export const isTripRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TripDoc> => ref.parent.id === 'trips';

function isTripSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TripDoc>;
function isTripSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TripDoc> | QueryDocumentSnapshot<TripDoc> {
  return isTripRef(snap.ref);
}

export { isTripSnap };

export const getTripsCollectionRef = (firestore: Firestore) => collection(firestore, 'trips') as CollectionReference<TripDoc>;

export const useTripsCollectionRef = () => {
  const firestore = useFirestore();
  return getTripsCollectionRef(firestore);
};
