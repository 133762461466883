import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import { defaults } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TripDuration } from '../../../collections/Trips';
import Tier from '../../../common/Tier';
import TripBudget from '../../../common/TripBudget';
import Catch from '../../../components/Catch';
import CheckboxFormControl from '../../../components/CheckboxFormControl';
import RangeFormControl from '../../../components/RangeFormControl';
import {
  useMyProfileHiddenSnap,
} from '../../../components/snapProviders/MyProfileHiddenSnapProvider';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function TripFiltersModalMain({ isOpen, onClose }: Props) {
  const { t } = useTranslation('TripFiltersModal');

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const handlePopoverClose = useCallback(() => {
    mixpanel.track('Trips Filter Closed');
    onClose();
  }, [onClose]);

  const schema = useMemo(
    () => yup.object().shape({
      age: yup
        .object()
        .label(t('age.label'))
        .shape({
          max: yup
            .number()
            .label(t('age.max.label'))
            .integer()
            .min(18)
            .max(100)
            .required(),
          min: yup
            .number()
            .label(t('age.min.label'))
            .integer()
            .min(18)
            .max(100)
            .required(),
        })
        .required(),
      height: yup
        .object()
        .label(t('height.label'))
        .shape({
          max: yup
            .number()
            .label(t('height.max.label'))
            .integer()
            .min(100)
            .max(300)
            .required(),
          min: yup
            .number()
            .label(t('height.min.label'))
            .integer()
            .min(100)
            .max(300)
            .required(),
        })
        .required(),
      tiers: yup
        .array()
        .label(t('tiers.label'))
        .of(
          yup
            .string()
            .label(t('tiers.item.label'))
            .oneOf(Object.values(Tier))
            .required(),
        )
        .required(),
      tripBudgets: yup
        .array()
        .label(t('tripBudgets.label'))
        .of(
          yup
            .string()
            .label(t('tripBudgets.item.label'))
            .oneOf(Object.values(TripBudget))
            .required(),
        )
        .required(),
      tripDurations: yup
        .array()
        .label(t('tripDurations.label'))
        .of(
          yup
            .string()
            .label(t('tripDurations.item.label'))
            .oneOf(Object.values(TripDuration))
            .required(),
        )
        .required(),
      weight: yup
        .object()
        .label(t('weight.label'))
        .shape({
          max: yup
            .number()
            .label(t('weight.max.label'))
            .integer()
            .min(30)
            .max(300)
            .required(),
          min: yup
            .number()
            .label(t('weight.min.label'))
            .integer()
            .min(30)
            .max(300)
            .required(),
        })
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => (defaults(
      {},
      myProfileHiddenDoc?.preferences ?? {},
      {
        age: {
          max: 100,
          min: 18,
        },
        height: {
          max: 300,
          min: 100,
        },
        tiers: [
          Tier.MORTAL,
          Tier.HERMES,
          Tier.DIONYSUS,
          Tier.POSEIDON,
          Tier.ZEUS,
        ],
        tripBudgets: [
          TripBudget.ECONOMY,
          TripBudget.STANDARD,
          TripBudget.PREMIUM,
          TripBudget.LUXURY,
          TripBudget.ULTIMATE,
        ],
        tripDurations: [
          TripDuration.WEEKEND,
          TripDuration.WEEK,
          TripDuration.MONTH,
          TripDuration.SEASON,
        ],
        weight: {
          max: 300,
          min: 30,
        },
      },
    )),
    [myProfileHiddenDoc],
  );

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      mixpanel.track('Trips Filter Updated', {
        ageMax: values.age.max,
        ageMin: values.age.min,
        heightMax: values.height.max,
        heightMin: values.height.min,
        tiers: values.tiers,
        tripBudgets: values.tripBudgets,
        tripDurations: values.tripDurations,
        weightMax: values.weight.max,
        weightMin: values.weight.min,
      });

      await setDoc(
        myProfileHiddenSnap.ref,
        {
          preferences: values,
        },
        { merge: true },
      );

      handlePopoverClose();
    },
    [handlePopoverClose, myProfileHiddenSnap.ref],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          isOpen={isOpen}
          onClose={handlePopoverClose}
          scrollBehavior="inside"
        >
          <ModalOverlay
            backdropFilter="saturate(180%) blur(20px)"
            backgroundColor="rgb(from var(--chakra-colors-chakra-body-bg) r g b / 0.5)"
          />

          <ModalContent mx={4}>
            <ModalCloseButton />

            <ModalHeader>
              {t('label')}
            </ModalHeader>

            <ModalBody>
              <VStack alignItems="stretch" gap={4}>
                <CheckboxFormControl
                  label={t('tripBudgets.label')}
                  name="tripBudgets"
                  options={{
                    [TripBudget.ECONOMY]: t('tripBudgets.option.economy'),
                    [TripBudget.STANDARD]: t('tripBudgets.option.standard'),
                    // eslint-disable-next-line perfectionist/sort-objects
                    [TripBudget.PREMIUM]: t('tripBudgets.option.premium'),
                    // eslint-disable-next-line perfectionist/sort-objects
                    [TripBudget.LUXURY]: t('tripBudgets.option.luxury'),
                    [TripBudget.ULTIMATE]: t('tripBudgets.option.ultimate'),
                  }}
                />

                <CheckboxFormControl
                  label={t('tripDurations.label')}
                  name="tripDurations"
                  options={{
                    [TripDuration.WEEKEND]: t('tripDurations.option.weekend'),
                    // eslint-disable-next-line perfectionist/sort-objects
                    [TripDuration.WEEK]: t('tripDurations.option.week'),
                    // eslint-disable-next-line perfectionist/sort-objects
                    [TripDuration.MONTH]: t('tripDurations.option.month'),
                    [TripDuration.SEASON]: t('tripDurations.option.season'),
                  }}
                />

                <CheckboxFormControl
                  label={t('tiers.label')}
                  name="tiers"
                  options={{
                    /* eslint-disable perfectionist/sort-objects */
                    [Tier.MORTAL]: t('tiers.option.mortal'),
                    [Tier.HERMES]: t('tiers.option.hermes'),
                    [Tier.DIONYSUS]: t('tiers.option.dionysus'),
                    [Tier.POSEIDON]: t('tiers.option.poseidon'),
                    [Tier.ZEUS]: t('tiers.option.zeus'),
                    /* eslint-enable perfectionist/sort-objects */
                  }}
                />

                <RangeFormControl
                  label={t('age.label')}
                  max={100}
                  min={18}
                  name="age"
                />

                <RangeFormControl
                  label={t('height.label')}
                  max={300}
                  min={100}
                  name="height"
                />

                <RangeFormControl
                  label={t('weight.label')}
                  max={300}
                  min={30}
                  name="weight"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={isSubmitting}
                loadingText={t('applyButton.loading')}
                onClick={() => handleSubmit()}
              >
                {t('applyButton.default')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  );
}

export default function TripFiltersModal(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripFiltersModalMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
