import { Center } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from 'reactfire';

import { useProfilesCollectionRef } from '../collections/Profiles';
import Catch from '../components/Catch';
import LogoIcon from '../components/LogoIcon';
import MyProfileRefProvider from '../components/refProviders/MyProfileRefProvider';
import ErrorFallbackScreen from './ErrorFallbackScreen';

export type Props = {
  fallback: React.ReactElement;
};

export function CheckProfileRefLayoutMain({ fallback }: Props) {
  const { data: user } = useUser({ suspense: true });

  const profilesCollectionRef = useProfilesCollectionRef();

  const profileRef = useMemo(
    () => (user?.uid ? doc(profilesCollectionRef, user.uid) : undefined),
    [profilesCollectionRef, user?.uid],
  );

  useEffect(
    () => {
      if (profileRef?.id) {
        mixpanel.identify(profileRef.id);
      }
    },
    [profileRef?.id],
  );

  if (!profileRef) {
    return fallback;
  }

  return (
    <MyProfileRefProvider
      profileRef={profileRef}
    >
      <Outlet />
    </MyProfileRefProvider>
  );
}

export default function CheckProfileRefLayout(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <CheckProfileRefLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
