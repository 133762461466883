import { Badge, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TripBudget from '../common/TripBudget';
import TripBudgetMap from '../common/TripBudgetMap';

export type Props = {
  tripBudget: TripBudget;
} & BoxProps;

export default function TripBudgetBadge({
  tripBudget,
}: Props) {
  const { t } = useTranslation('TripBudget');

  if (tripBudget === TripBudget.ECONOMY) {
    return (
      <Badge alignItems="center" colorScheme={TripBudgetMap[TripBudget.ECONOMY].colorScheme} display="flex" gap={1} my="-1px">
        {t(`${tripBudget}.shortLabel`)}
      </Badge>
    );
  }

  if (tripBudget === TripBudget.STANDARD) {
    return (
      <Badge alignItems="center" colorScheme={TripBudgetMap[TripBudget.STANDARD].colorScheme} display="flex" gap={1} my="-1px">
        {t(`${tripBudget}.shortLabel`)}
      </Badge>
    );
  }

  if (tripBudget === TripBudget.PREMIUM) {
    return (
      <Badge alignItems="center" colorScheme={TripBudgetMap[TripBudget.PREMIUM].colorScheme} display="flex" gap={1} my="-1px">
        {t(`${tripBudget}.shortLabel`)}
      </Badge>
    );
  }

  if (tripBudget === TripBudget.LUXURY) {
    return (
      <Badge alignItems="center" colorScheme={TripBudgetMap[TripBudget.LUXURY].colorScheme} display="flex" gap={1} my="-1px">
        {t(`${tripBudget}.shortLabel`)}
      </Badge>
    );
  }

  if (tripBudget === TripBudget.ULTIMATE) {
    return (
      <Badge alignItems="center" colorScheme={TripBudgetMap[TripBudget.ULTIMATE].colorScheme} display="flex" gap={1} my="-1px">
        {t(`${tripBudget}.shortLabel`)}
      </Badge>
    );
  }

  return null;
}
