import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import { TripDuration } from '../collections/Trips';
import AppLanguage from '../common/AppLanguage';
import Gender from '../common/Gender';
import Sexuality from '../common/Sexuality';

export interface Data {
  activities: string[];
  audienceGenders: Gender[];
  departure: string;
  destination: string;
  duration: TripDuration;
  language: AppLanguage;
  organizer: {
    age: number;
    gender: Gender;
    name: string;
    sexuality: Sexuality;
  }
}

export type Result = {
  description: string;
};

export default function useGenerateTripDescription() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'generateTripDescription'), [functions]);
}
