/* eslint-disable perfectionist/sort-enums */
enum TripBudget {
  ECONOMY = 'ECONOMY',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  LUXURY = 'LUXURY',
  ULTIMATE = 'ULTIMATE',
}

export default TripBudget;
