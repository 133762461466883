import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Data {
  initData: string;
}

export type Result = void;

export default function useTelegramAuthWebApp() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-authWebApp'), [functions]);
}
