import {
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LuChevronLeft, LuSearch } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import useDimensions from '../../hooks/useDimensions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import DestinationList from './DestinationList';

export function DestinationsScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'DestinationsScreen' }); }, []);

  const insets = useInsets();

  const { t } = useTranslation('DestinationsScreen');

  const [queryInputValue, setQueryInputValue] = useState<string>('');
  const [query, setQuery] = useState<string>(queryInputValue);

  const onQueryInputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryInputValue(e.target.value);
    },
    [],
  );

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setQuery(queryInputValue),
        600,
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    [queryInputValue],
  );

  const [ref, { height, width }] = useDimensions();

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        top={`max(${insets.top}, var(--chakra-space-2))`}
        zIndex={10}
      >
        <Container maxW="lg">
          <HStack gap={4}>
            <IconButton
              aria-label={t('backButton.default')}
              as={Link}
              icon={<Icon as={LuChevronLeft} />}
              to=".."
            />

            <Heading
              flex={1}
              fontSize="4xl"
              lineHeight="40px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {t('title')}
            </Heading>
          </HStack>
        </Container>
      </Box>

      <Box
        left={0}
        position="absolute"
        right={0}
        style={{
          top: `calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10) + var(--chakra-space-2))`,
        }}
        zIndex={20}
      >
        <Container maxW="lg">
          <InputGroup
            size="lg"
          >
            <InputLeftElement>
              <Icon as={LuSearch} />
            </InputLeftElement>

            <Input
              _dark={{
                backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
              }}
              _light={{
                backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
              }}
              backdropFilter="saturate(180%) blur(20px)"
              border="none"
              onChange={onQueryInputValueChange}
              placeholder={t('searchPlaceholder')}
              value={queryInputValue}
            />
          </InputGroup>
        </Container>
      </Box>

      <InsetsProvider
        bottom={insets.bottom}
        left={insets.left}
        right={insets.right}
        top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
      >
        <Container h="100%" maxW="lg" px={0} ref={ref}>
          {height && width ? (
            <DestinationList
              height={height}
              query={query}
              width={width}
            />
          ) : null}
        </Container>
      </InsetsProvider>
    </Box>
  );
}

export default function DestinationsScreen() {
  const { t } = useTranslation('DestinationsScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <DestinationsScreenMain />
      </Suspense>
    </Catch>
  );
}
