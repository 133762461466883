import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Icon,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';

import { isProfileComplete } from '../../../collections/Profiles';
import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import { useProfileSnap } from '../../../components/snapProviders/ProfileSnapProvider';
import useCurrentTime from '../../../hooks/useCurrentTime';

export type Props = AvatarProps;

export function ProfileAvatarMain({ ...avatarProps }: Props) {
  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  if (!isProfileComplete(profileDoc)) {
    throw new Error('Profile is not complete');
  }

  const src = useMemo(
    () => {
      const uri = profileDoc.pictures[profileDoc.primaryPictureIndex ?? 0]?.imgixUrl;

      if (!uri) {
        return undefined;
      }

      return getPhotoSizeUrl({
        faces: true,
        height: 48,
        uri,
        width: 48,
      });
    },
    [profileDoc.pictures, profileDoc.primaryPictureIndex],
  );

  const currentTime = useCurrentTime();

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      src={src}
    >
      {currentTime.getTime() - 1000 * 60 < (
        profileDoc.lastSeenAt?.toMillis() ?? 0
      ) ? (
        <AvatarBadge
          bg="green.500"
          border="none"
          boxSize={3}
          transform="none"
        />
        ) : null}
    </Avatar>
  );
}

export default function ProfileAvatar(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileAvatarMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
