import {
  AspectRatio,
  Button,
  Card,
  CardBody,
  Center,
  Grid,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuPalmtree } from 'react-icons/lu';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { Link } from 'react-router-dom';

import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useJoyrideLocale from '../../hooks/useJoyrideLocale';
import useJoyrideStylesOptions from '../../hooks/useJoyrideStylesOptions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Destination, { DestinationLoading } from './Destination';

export function DestinationsListMain() {
  const { t } = useTranslation('WishlistScreen', { keyPrefix: 'DestinationsList' });

  const myProfileSnap = useMyProfileSnap();

  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const destinationRefs = useMemo(() => myProfileDoc.wishlistDestinationRefs ?? [], [myProfileDoc]);

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.destinationsListStep.content'),
      placement: 'center',
      target: '.destinationsList',
    },
    {
      content: t('tour.addDestinationButtonStep.content'),
      target: '.addDestinationButton',
    },
  ]), [t]);

  const joyrideLocale = useJoyrideLocale();
  const joyrideStylesOptions = useJoyrideStylesOptions();
  const [run, setRun] = useState<boolean>(() => localStorage.getItem('wishlistTourStatus') !== 'finished');
  const handleJoyrideCallback = useCallback(({ status }: CallBackProps) => {
    if (['finished', 'skipped'].includes(status)) {
      localStorage.setItem('wishlistTourStatus', 'finished');
      setRun(false);
    }
  }, []);

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        locale={joyrideLocale}
        run={run}
        showSkipButton
        steps={steps}
        styles={{ options: joyrideStylesOptions }}
      />

      {!destinationRefs.length ? (
        <VStack alignItems="stretch" className="destinationsList" h="100%">
          <Center flex={1}>
            <Text textAlign="center">{t('emptyList.body')}</Text>
          </Center>

          <Button
            as={Link}
            className="addDestinationButton"
            leftIcon={<Icon as={LuPalmtree} />}
            to="destinations"
          >
            {t('addDestinationButton.default')}
          </Button>
        </VStack>
      ) : (
        <Grid className="destinationsList" gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          {destinationRefs.map((destinationRef) => (
            <Destination destinationRef={destinationRef} key={destinationRef.id} />
          ))}

          <AspectRatio ratio={9 / 16}>
            <Card
              as={Link}
              className="addDestinationButton"
              h="100%"
              to="destinations"
              w="100%"
            >
              <CardBody>
                <Center h="100%">
                  <Icon as={LuPalmtree} boxSize={5} />
                </Center>
              </CardBody>
            </Card>
          </AspectRatio>
        </Grid>
      )}
    </>
  );
}

export function DestinationsListLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
      <DestinationLoading />
      <DestinationLoading />
      <DestinationLoading />
      <DestinationLoading />
    </Grid>
  );
}

export default function DestinationsList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<DestinationsListLoading />}>
        <DestinationsListMain />
      </Suspense>
    </Catch>
  );
}
