import { Button, Text, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';

import { DestinationDoc } from '../../collections/Destinations';
import {
  TripDoc,
  TripDuration,
  TripStatus,
  useTripsCollectionRef,
} from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import TripBudget from '../../common/TripBudget';
import { UnsplashPicture } from '../../common/UnsplashPicture';
import ActivitiesFormControl from '../../components/ActivitiesFormControl';
import BudgetFormControl from '../../components/BudgetFormControl';
import CoverFormControl from '../../components/CoverFormControl';
import RadioFormControl from '../../components/RadioFormControl';
import {
  useMyProfileHiddenSnap,
} from '../../components/snapProviders/MyProfileHiddenSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextFormControl from '../../components/TextFormControl';
import TripDescriptionFormControl from '../../components/TripDescriptionFormControl';

export type Props = {
  destinationRef: DocumentReference<DestinationDoc>;
  onComplete: (tripRef: DocumentReference<TripDoc>) => void;
};

export default function TripForm({ destinationRef, onComplete }: Props) {
  const { i18n, t } = useTranslation('TripCreateScreen', { keyPrefix: 'TripForm' });

  const { data: destinationSnap } = useFirestoreDoc(destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination does not exist');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const schema = useMemo(
    () => yup.object().shape({
      activities: yup
        .array()
        .label(t('activities.label'))
        .min(0)
        .max(20)
        .of(
          yup
            .string()
            .label(t('activities.item.label'))
            .required(),
        )
        .required(),
      budget: yup
        .string()
        .label(t('budget.label'))
        .oneOf(Object.values(TripBudget))
        .required(),
      departure: yup
        .string()
        .label(t('departure.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      duration: yup
        .string()
        .label(t('duration.label'))
        .oneOf(Object.values(TripDuration))
        .required(),
      picture: yup
        .mixed<UnsplashPicture>()
        .label(t('picture.label'))
        .required()
        .nullable(),
    }),
    [t],
  );

  const myProfileSnap = useMyProfileSnap();
  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      activities: [],
      budget: myProfileHiddenDoc.maxTripBudget,
      departure: moment
        .utc()
        .startOf('month')
        .add(1, 'month')
        .format('YYYY-MM-DD'),
      description: '',
      duration: TripDuration.WEEK,
      picture: destinationDoc.picture ?? null,
    }),
    [destinationDoc.picture, myProfileHiddenDoc.maxTripBudget],
  );

  const tripsCollectionRef = useTripsCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      if (!values.picture) {
        return;
      }

      const tripRef = doc(tripsCollectionRef);

      await setDoc(
        tripRef,
        {
          _v: 1,
          activities: values.activities,
          budget: values.budget,
          createdAt: Timestamp.now(),
          departure: values.departure,
          description: values.description,
          destinationRef,
          duration: values.duration,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          organizerRef: myProfileSnap.ref,
          picture: values.picture,
          status: TripStatus.PUBLISHED,
          updatedAt: Timestamp.now(),
        },
      );

      mixpanel.track('Trip Created', {
        activities: values.activities,
        budget: values.budget,
        createdAt: new Date(),
        departure: values.departure,
        description: values.description,
        destinationId: destinationRef.id,
        duration: values.duration,
        organizerId: myProfileSnap.ref.id,
        status: TripStatus.PUBLISHED,
        updatedAt: new Date(),
      });

      onComplete(tripRef);
    },
    [
      destinationRef,
      onComplete,
      myProfileSnap.ref,
      tripsCollectionRef,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <VStack alignItems="stretch" gap={4} h="100%">
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <VStack alignItems="stretch" gap={0}>
              <Text fontSize="3xl" fontWeight="bold">
                {destinationDoc.name[i18n.language as AppLanguage]}
              </Text>

              <Text fontSize="xl">
                {destinationDoc.countryEmoji}
                {' '}
                {destinationDoc.countryName[i18n.language as AppLanguage]}
              </Text>
            </VStack>

            <CoverFormControl
              destination={destinationDoc.name.en}
              label={t('picture.label')}
              name="picture"
            />

            <BudgetFormControl
              label={t('budget.label')}
              name="budget"
            />

            <TextFormControl
              label={t('departure.label')}
              name="departure"
              type="date"
            />

            <RadioFormControl
              label={t('duration.label')}
              name="duration"
              options={{
                [TripDuration.MONTH]: t('duration.option.month'),
                [TripDuration.SEASON]: t('duration.option.season'),
                [TripDuration.WEEK]: t('duration.option.week'),
                [TripDuration.WEEKEND]: t('duration.option.weekend'),
              }}
            />

            <ActivitiesFormControl
              destinationId={destinationRef.id}
              label={t('activities.label')}
              name="activities"
              placeholder={t('activities.placeholder')}
            />

            <TripDescriptionFormControl
              activities={values.activities}
              departure={values.departure}
              destinationId={destinationRef.id}
              duration={values.duration}
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
            />
          </VStack>

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
