import {
  Center,
  Container,
  Text,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'reactfire';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import GenderForm from './GenderForm';

export function WelcomeScreenMain() {
  useEffect(() => { mixpanel.track_pageview({ screen: 'WelcomeScreen' }); }, []);

  const { t } = useTranslation('WelcomeScreen');

  const insets = useInsets();

  const { data: user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  return (
    <VStack alignItems="stretch" gap={4} h="100%" position="relative">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <Center>
          <LogoFull h={16} w={48} />
        </Center>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text textAlign="center">
            {t('welcomeText')}
          </Text>
        </VStack>
      </Container>

      <Container
        flexShrink={0}
        pb={`max(${insets.bottom}, var(--chakra-space-4))`}
        pt={2}
      >
        <GenderForm />
      </Container>
    </VStack>
  );
}

export default function WelcomeScreen() {
  const { t } = useTranslation('WelcomeScreen');

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <WelcomeScreenMain />
      </Suspense>
    </Catch>
  );
}
