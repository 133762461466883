import { Button, Icon, IconButton } from '@chakra-ui/react';
import {
  and,
  doc,
  getDocs,
  limit,
  or,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHardHat, LuSettings } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../collections/Conversations';
import { useProfilesCollectionRef } from '../../collections/Profiles';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useShowError from '../../hooks/useShowError';

export function SupportButtonMain() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SupportButton' });

  const showError = useShowError();

  const myProfileSnap = useMyProfileSnap();

  const conversationsCollectionRef = useConversationsCollectionRef();
  const profilesCollectionRef = useProfilesCollectionRef();
  const adminRef = useMemo(
    () => doc(profilesCollectionRef, '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1'),
    [profilesCollectionRef],
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(
    () => {
      setIsLoading(true);
      getDocs(
        query(
          conversationsCollectionRef,
          and(
            or(
              where('participantRefs', '==', [myProfileSnap.ref, adminRef]),
              where('participantRefs', '==', [adminRef, myProfileSnap.ref]),
            ),
            where('status', '==', ConversationStatus.OPENED),
          ),
          limit(1),
        ),
      )
        .then(async (conversationsSnap) => {
          if (conversationsSnap.docs.length > 0) {
            return conversationsSnap.docs[0].ref;
          }

          const conversationRef = doc(conversationsCollectionRef);
          await setDoc(
            conversationRef,
            {
              _v: 1,
              expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24),
              lastActionAt: Timestamp.now(),
              notReadByRefs: [],
              openedAt: Timestamp.now(),
              participantRefs: [myProfileSnap.ref, adminRef],
              readByRefs: [myProfileSnap.ref, adminRef],
              status: ConversationStatus.OPENED,
              tripRefs: [],
              typingParticipantRefs: [],
            },
          );
          return conversationRef;
        })
        .finally(() => setIsLoading(false))
        .then((conversationRef) => navigate(`/chats/${conversationRef.id}`))
        .catch(showError);
    },
    [adminRef, conversationsCollectionRef, navigate, myProfileSnap.ref, showError],
  );

  return (
    <Button
      isLoading={isLoading}
      justifyContent="start"
      leftIcon={<Icon as={LuHardHat} />}
      loadingText={t('loading')}
      onClick={handleClick}
    >
      {t('default')}
    </Button>
  );
}

export default function SupportButton() {
  const { t } = useTranslation('SettingsScreen', { keyPrefix: 'SettingsMenu' });

  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label={t('settingsButton.default')}
            icon={<Icon as={LuSettings} />}
            isLoading
          />
        )}
      >
        <SupportButtonMain />
      </Suspense>
    </Catch>
  );
}
