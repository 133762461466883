import {
  Box,
  Center,
  Container,
  Text,
} from '@chakra-ui/react';
import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { clamp, defaults } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { CallBackProps, Step } from 'react-joyride';

import { TripDuration, TripStatus } from '../../../collections/Trips';
import Gender from '../../../common/Gender';
import TripAlgoliaSearchRecord from '../../../common/TripAlgoliaSearchRecord';
import TripBudget from '../../../common/TripBudget';
import { useAlgoliaInsightsClient } from '../../../components/AlgoliaInsightsClientProvider';
import { useAlgoliaSearchClient } from '../../../components/AlgoliaSearchClientProvider';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import {
  useMyProfileHiddenSnap,
} from '../../../components/snapProviders/MyProfileHiddenSnapProvider';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';
import useJoyrideLocale from '../../../hooks/useJoyrideLocale';
import useJoyrideStylesOptions from '../../../hooks/useJoyrideStylesOptions';
import useShowError from '../../../hooks/useShowError';
import ErrorFallbackScreen from '../../ErrorFallbackScreen';
import Trip from './Trip';

export type Props = {
  height: number;
  width: number;
};

export function TripListMain({ height, width }: Props) {
  const { t } = useTranslation('TripsScreen', { keyPrefix: 'TripList' });
  const showError = useShowError();

  const client = useAlgoliaSearchClient();
  const algoliaInsights = useAlgoliaInsightsClient();
  const tripsIndex = useMemo(() => client.initIndex('trips'), [client]);

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const preferences = useMemo(
    () => defaults(
      {},
      myProfileHiddenDoc?.preferences ?? {},
      {
        age: {
          max: 100,
          min: 18,
        },
        height: {
          max: 300,
          min: 100,
        },
        tripBudgets: [
          TripBudget.ECONOMY,
          TripBudget.STANDARD,
          TripBudget.PREMIUM,
          TripBudget.LUXURY,
          TripBudget.ULTIMATE,
        ],
        tripDurations: [
          TripDuration.WEEKEND,
          TripDuration.WEEK,
          TripDuration.MONTH,
          TripDuration.SEASON,
        ],
        weight: {
          max: 300,
          min: 30,
        },
      },
    ),
    [myProfileHiddenDoc?.preferences],
  );

  const filters = useMemo(
    () => {
      const nextFilters: (string | string[])[] = [];
      nextFilters.push(`status: ${TripStatus.PUBLISHED}`);
      nextFilters.push(`organizer.gender: ${Gender.MALE}`);

      if (myProfileDoc.languages) {
        const organizerLanguagesFilter: string[] = myProfileDoc.languages.map(
          (lng) => `organizer.languages: ${lng}`,
        );

        if (organizerLanguagesFilter.length) {
          nextFilters.push(`(${organizerLanguagesFilter.join(' OR ')})`);
        }
      }

      nextFilters.push(`(${preferences.tripBudgets.map(
        (budget) => `budget: ${budget}`,
      ).join(' OR ')})`);

      nextFilters.push(`(${preferences.tripDurations.map(
        (duration) => `duration: ${duration}`,
      ).join(' OR ')})`);

      return nextFilters.join(' AND ');
    },
    [
      preferences.tripBudgets,
      preferences.tripDurations,
      myProfileDoc,
    ],
  );

  const numericFilters = useMemo(
    () => {
      const nextOptionalFilters: (string | string[])[] = [];

      nextOptionalFilters.push(`organizer.age: ${preferences.age.min} TO ${preferences.age.max}`);
      nextOptionalFilters.push(`organizer.height: ${preferences.height.min} TO ${preferences.height.max}`);
      nextOptionalFilters.push(`organizer.weight: ${preferences.weight.min} TO ${preferences.weight.max}`);

      return nextOptionalFilters.join(' AND ');
    },
    [
      preferences.age.max,
      preferences.age.min,
      preferences.height.max,
      preferences.height.min,
      preferences.weight.max,
      preferences.weight.min,
    ],
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trips, setTrips] = useState<TripAlgoliaSearchRecord[]>([]);
  const [queryId, setQueryId] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      setIsLoading(true);
      tripsIndex.search<TripAlgoliaSearchRecord>('', {
        clickAnalytics: true,
        // enablePersonalization: true,
        filters,
        length: 1000,
        numericFilters,
        offset: 0,
        userToken: myProfileSnap.ref.id,
      }).finally(() => {
        setIsLoading(false);
      }).then((response) => {
        setTrips(response.hits);
        setQueryId(response.queryID);
      }).catch(showError);
    },
    [filters, numericFilters, myProfileSnap.ref.id, showError, tripsIndex],
  );

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const animationIndex = useRef(0);

  const [pages, api] = useSprings(trips.length, (i) => ({
    borderRadius: 0,
    display: 'block',
    scale: 1,
    x: i * width,
  }));

  const bind = useDrag(({
    active, cancel, direction: [xDir], movement: [mx],
  }) => {
    if (active && Math.abs(mx) > 100) {
      animationIndex.current = clamp(
        animationIndex.current + (xDir > 0 ? -1 : 1),
        0,
        trips.length - 1,
      );
      setCurrentIndex(animationIndex.current);
      cancel();
    }

    api.start((i) => {
      if (i < animationIndex.current - 1 || i > animationIndex.current + 1) {
        return {
          display: 'none',
        };
      }

      const borderRadius = active
        ? (clamp(
          Math.abs(mx),
          0,
          100,
        ) / 100) * 32
        : 0;
      const x = (i - animationIndex.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return {
        borderRadius, display: 'block', scale, x,
      };
    });
  }, {
    axis: 'x',
    preventDefault: true,
    threshold: 10,
  });

  useEffect(
    () => {
      if (trips[currentIndex]) {
        const tripRecord = trips[currentIndex];

        mixpanel.track('Trips List Scrolled', {
          budget: tripRecord.budget,
          createdAt: new Date(tripRecord.createdAt),
          departure: tripRecord.departure,
          destinationCountryId: tripRecord.destinationCountryId,
          destinationId: tripRecord.destinationId,
          duration: tripRecord.duration,
          index: currentIndex,
          organizerAge: tripRecord.organizer.age,
          organizerGender: tripRecord.organizer.gender,
          organizerHeight: tripRecord.organizer.height,
          organizerId: tripRecord.organizer.id,
          organizerLanguages: tripRecord.organizer.languages,
          organizerName: tripRecord.organizer.name,
          organizerOriginCityId: tripRecord.organizer.origin.city.id,
          organizerOriginCountryId: tripRecord.organizer.origin.country?.id,
          organizerScore: tripRecord.organizer.score,
          organizerSexuality: tripRecord.organizer.sexuality,
          organizerSexualityGender: tripRecord.organizer.sexualityGender,
          organizerTier: tripRecord.organizer.tier,
          organizerWeight: tripRecord.organizer.weight,
          organizerZodiac: tripRecord.organizer.zodiac,
          tripId: tripRecord.objectID,
        });
      }
    },
    [currentIndex, trips],
  );

  useEffect(
    () => {
      if (trips[currentIndex]) {
        algoliaInsights.pushEvents({
          events: [
            {
              authenticatedUserToken: myProfileSnap.ref.id,
              eventName: 'Trip View',
              eventType: 'view',
              index: 'trips',
              objectIDs: [trips[currentIndex].objectID],
              timestamp: Date.now(),
              userToken: myProfileSnap.ref.id,
            },
          ],
        }).catch(() => { });
      }
    },
    [algoliaInsights, currentIndex, myProfileSnap.ref.id, trips],
  );

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.tripsListStep.content'),
      placement: 'center',
      target: '.tripsList',
      title: t('tour.tripsListStep.title'),
    },
    {
      content: t('tour.tripStep.content'),
      placement: 'center',
      target: '.trip',
    },
    {
      content: t('tour.filterStep.content'),
      target: '.filter',
    },
    {
      content: t('tour.tripInfoStep.content'),
      target: '.tripInfo',
    },
    {
      content: t('tour.tripActionStep.content'),
      target: '.tripAction',
    },
  ]), [t]);

  const joyrideLocale = useJoyrideLocale();
  const joyrideStylesOptions = useJoyrideStylesOptions();
  const [run, setRun] = useState<boolean>(() => localStorage.getItem('tripsListTourStatus') !== 'finished');
  const handleJoyrideCallback = useCallback(({ status }: CallBackProps) => {
    if (['finished', 'skipped'].includes(status)) {
      localStorage.setItem('tripsListTourStatus', 'finished');
      setRun(false);
    }
  }, []);

  if (trips.length) {
    return (
      <>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          locale={joyrideLocale}
          run={run}
          showSkipButton
          steps={steps}
          styles={{ options: joyrideStylesOptions }}
        />

        <Box
          className="tripsList"
          h={`${height}px`}
          overflow="hidden"
          position="relative"
          w={`${width}px`}
        >
          {pages.map(({
            borderRadius, display, scale, x,
          }, searchResultPosition) => (
            Math.abs(currentIndex - searchResultPosition) > 5
              ? null
              : (
                <Box
                  as={animated.div}
                  h={`${height}px`}
                  key={trips[searchResultPosition].objectID}
                  position="absolute"
                  w={`${width}px`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...bind()}
                  style={{
                    display,
                    touchAction: 'none',
                    x,
                  }}
                >
                  <Trip
                    as={animated.div}
                    height={height}
                    overflow="hidden"
                    queryId={queryId}
                    searchResultPosition={searchResultPosition}
                    style={{
                      borderRadius: borderRadius as unknown as number,
                      scale: scale as unknown as number,
                      touchAction: 'none',
                    }}
                    tripRecord={trips[searchResultPosition]}
                    width={width}
                  />
                </Box>
              )
          ))}
        </Box>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function TripList(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TripListMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
