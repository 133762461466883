import {
  AspectRatio,
  Button,
  Card,
  CardBody,
  Center,
  Grid,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { TripStatus, useTripsCollectionRef } from '../../collections/Trips';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TripSnapProvider from '../../components/snapProviders/TripSnapProvider';
import useJoyrideLocale from '../../hooks/useJoyrideLocale';
import useJoyrideStylesOptions from '../../hooks/useJoyrideStylesOptions';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Trip, { TripLoading } from './Trip';

export function TripListMain() {
  const { t } = useTranslation('PlansScreen', { keyPrefix: 'TripList' });

  const myProfileSnap = useMyProfileSnap();

  const tripsCollectionRef = useTripsCollectionRef();
  const { data: publishedTripsSnap } = useFirestoreCollection(
    query(
      tripsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('status', '==', TripStatus.PUBLISHED),
      orderBy('departure', 'asc'),
    ),
  );

  const { data: unpublishedTripsSnap } = useFirestoreCollection(
    query(
      tripsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('status', '==', TripStatus.UNPUBLISHED),
      orderBy('departure', 'asc'),
    ),
  );

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.myTripsListStep.content'),
      placement: 'center',
      target: '.myTripsList',
    },
    {
      content: t('tour.createMyTripStep.content'),
      target: '.createMyTripButton',
    },
  ]), [t]);

  const joyrideLocale = useJoyrideLocale();
  const joyrideStylesOptions = useJoyrideStylesOptions();
  const [run, setRun] = useState<boolean>(() => localStorage.getItem('myTripsListTourStatus') !== 'finished');
  const handleJoyrideCallback = useCallback(({ status }: CallBackProps) => {
    if (['finished', 'skipped'].includes(status)) {
      localStorage.setItem('myTripsListTourStatus', 'finished');
      setRun(false);
    }
  }, []);

  if (!publishedTripsSnap.docs.length && !unpublishedTripsSnap.docs.length) {
    return (
      <>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          locale={joyrideLocale}
          run={run}
          showSkipButton
          steps={steps}
          styles={{ options: joyrideStylesOptions }}
        />

        <VStack alignItems="stretch" className="myTripsList" h="100%">
          <Center flex={1}>
            <Text textAlign="center">{t('emptyList.body')}</Text>
          </Center>

          <Button
            as={Link}
            className="createMyTripButton"
            leftIcon={<Icon as={LuPlusSquare} />}
            to="/plans/add"
          >
            {t('addTripButton.default')}
          </Button>
        </VStack>
      </>
    );
  }

  return (
    <VStack alignItems="stretch" className="myTripsList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Text fontSize="sm">
          {t('publishedTrips')}
        </Text>

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          {publishedTripsSnap.docs.map((tripSnap) => (
            <TripSnapProvider key={tripSnap.id} tripSnap={tripSnap}>
              <Trip />
            </TripSnapProvider>
          ))}

          <AspectRatio ratio={9 / 16}>
            <Card
              as={Link}
              className="createMyTripButton"
              h="100%"
              to="add"
              w="100%"
            >
              <CardBody>
                <Center h="100%">
                  <Icon as={LuPlusSquare} boxSize={5} />
                </Center>
              </CardBody>
            </Card>
          </AspectRatio>
        </Grid>
      </VStack>

      {unpublishedTripsSnap.docs.length && (
        <VStack alignItems="stretch" gap={2}>
          <Text fontSize="sm">
            {t('unpublishedTrips')}
          </Text>

          <Grid gap={2} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
            {unpublishedTripsSnap.docs.map((tripSnap) => (
              <TripSnapProvider key={tripSnap.id} tripSnap={tripSnap}>
                <Trip />
              </TripSnapProvider>
            ))}
          </Grid>
        </VStack>
      )}
    </VStack>
  );
}

export function TripListLoading() {
  return (
    <VStack alignItems="stretch" className="myTripsList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <TripLoading />
          <TripLoading />
          <TripLoading />
          <TripLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function TripList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripListLoading />}>
        <TripListMain />
      </Suspense>
    </Catch>
  );
}
