import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

import Tier from '../common/Tier';

export type Data = {
  cancelUrl: string;
  successUrl: string;
  tier: Tier;
};

export type Result = null | string;

export default function useStripeCreateCheckoutSession() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'stripe-createCheckoutSession'), [functions]);
}
