import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { useField } from 'formik';
import _ from 'lodash';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuBot } from 'react-icons/lu';
import { useFirestoreDoc } from 'reactfire';

import { useDestinationsCollectionRef } from '../../collections/Destinations';
import { isProfileComplete } from '../../collections/Profiles';
import { TripDuration } from '../../collections/Trips';
import AppLanguage from '../../common/AppLanguage';
import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import useGenerateTripDescription from '../../functions/useGenerateTripDescription';
import useShowError from '../../hooks/useShowError';
import Catch from '../Catch';
import { useMyProfileSnap } from '../snapProviders/MyProfileSnapProvider';

export interface Props {
  activities: string[];
  departure: string;
  destinationId: string;
  duration: TripDuration;
  label: string;
  name: string;
  placeholder: string;
}

export function TripDescriptionFormControlMain({
  activities,
  departure,
  destinationId,
  duration,
  label,
  name,
  placeholder,
}: Props) {
  const { i18n } = useTranslation('TripDescriptionFormControl');

  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const destinationsCollectionRef = useDestinationsCollectionRef();
  const destinationRef = useMemo(
    () => doc(destinationsCollectionRef, destinationId),
    [destinationId, destinationsCollectionRef],
  );
  const { data: destinationSnap } = useFirestoreDoc(destinationRef);

  if (!destinationSnap.exists()) {
    throw new Error('Destination not found');
  }

  const destinationDoc = useMemo(() => destinationSnap.data(), [destinationSnap]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      helper.setValue(e.target.value)
        .catch(showError);
    },
    [helper, showError],
  );

  const [loading, setLoading] = useState<boolean>(false);

  const generateTripDescription = useGenerateTripDescription();
  const handleGenerateClick = useCallback(
    () => {
      setLoading(true);

      if (!myProfileDoc || !isProfileComplete(myProfileDoc)) {
        throw new Error('Profile is not complete');
      }

      let audienceGenders: Gender[];

      switch (myProfileDoc.sexuality) {
        case Sexuality.GAY: {
          audienceGenders = [myProfileDoc.gender];
          break;
        }
        case Sexuality.BISEXUAL: {
          audienceGenders = [Gender.FEMALE, Gender.MALE];
          break;
        }
        case Sexuality.STRAIGHT:
        default: {
          if (myProfileDoc.gender === Gender.FEMALE) {
            audienceGenders = [Gender.MALE];
          } else {
            audienceGenders = [Gender.FEMALE];
          }
        }
      }

      generateTripDescription({
        activities,
        audienceGenders,
        departure,
        destination: _.compact([
          destinationDoc.name[i18n.language as AppLanguage],
          destinationDoc.countryName[i18n.language as AppLanguage],
        ]).join(', '),
        duration,
        language: i18n.language as AppLanguage,
        organizer: {
          age: myProfileDoc.age,
          gender: myProfileDoc.gender,
          name: myProfileDoc.name,
          sexuality: myProfileDoc.sexuality,
        },
      })
        .then(
          ({ data: { description } }) => {
            setLoading(false);
            return helper.setValue(description);
          },
        )
        .catch((err) => {
          showError(err);
          setLoading(false);
        });
    },
    [
      destinationDoc.countryName,
      destinationDoc.name,
      activities,
      departure,
      duration,
      generateTripDescription,
      helper,
      i18n.language,
      myProfileDoc,
      showError,
    ],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Textarea
          onChange={handleChange}
          placeholder={placeholder}
          pr={9}
          rows={10}
          value={input.value}
        />

        <InputRightElement>
          <IconButton
            aria-label="Generate"
            icon={<Icon as={LuBot} />}
            isLoading={loading}
            onClick={handleGenerateClick}
            size="sm"
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}

export default function TripDescriptionFormControl(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripDescriptionFormControlMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
