import {
  Box,
  BoxProps,
  HStack,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import {
  and,
  doc,
  getDocs,
  limit,
  or,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../../collections/Conversations';
import { useProfilesCollectionRef } from '../../../collections/Profiles';
import Gender from '../../../common/Gender';
import Tier from '../../../common/Tier';
import TierMap from '../../../common/TierMap';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';

export type Props = BoxProps;

enum CardType {
  APPLY,
  UNAVAILABLE,
  CURRENT,
  DOWNGRADE,
}

export default function ArtemisButton(boxProps: Props) {
  const { t } = useTranslation('MyProfileScreen', { keyPrefix: 'Subscription' });

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const cardType = useMemo<CardType>(() => {
    switch (myProfileDoc.tier) {
      case Tier.ARTEMIS:
        return CardType.CURRENT;

      case Tier.ATHENA:
      case Tier.APHRODITE:
      case Tier.HERA:
        return CardType.DOWNGRADE;

      default:
        return CardType.APPLY;
    }
  }, [myProfileDoc]);

  const conversationsCollectionRef = useConversationsCollectionRef();
  const profilesCollectionRef = useProfilesCollectionRef();
  const adminRef = useMemo(
    () => doc(profilesCollectionRef, '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1'),
    [profilesCollectionRef],
  );
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    getDocs(
      query(
        conversationsCollectionRef,
        and(
          or(
            where('participantRefs', '==', [myProfileSnap.ref, adminRef]),
            where('participantRefs', '==', [adminRef, myProfileSnap.ref]),
          ),
          where('status', '==', ConversationStatus.OPENED),
        ),
        limit(1),
      ),
    )
      .then((conversationsSnap) => {
        if (conversationsSnap.docs.length > 0) {
          navigate(`/chats/${conversationsSnap.docs[0].id}`);
        } else {
          const conversationRef = doc(conversationsCollectionRef);
          setDoc(
            conversationRef,
            {
              _v: 1,
              expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24),
              lastActionAt: Timestamp.now(),
              notReadByRefs: [adminRef],
              openedAt: Timestamp.now(),
              participantRefs: [myProfileSnap.ref, adminRef],
              readByRefs: [myProfileSnap.ref],
              status: ConversationStatus.OPENED,
              tripRefs: [],
              typingParticipantRefs: [],
            },
          )
            .then(() => {
              navigate(`/chats/${conversationRef.id}`);
            })
            .catch(() => { });
        }
      })
      .catch(() => { });
  }, [adminRef, conversationsCollectionRef, navigate, myProfileSnap.ref]);

  if (cardType === CardType.DOWNGRADE) {
    return null;
  }

  if (myProfileDoc.gender !== Gender.FEMALE) {
    return null;
  }

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      _dark={{
        backgroundColor: `rgb(from var(--chakra-colors-${TierMap[Tier.ARTEMIS].colorScheme}-200) r g b / 0.16)`,
        color: `var(--chakra-colors-${TierMap[Tier.ARTEMIS].colorScheme}-200)`,
      }}
      _light={{
        backgroundColor: `var(--chakra-colors-${TierMap[Tier.ARTEMIS].colorScheme}-100)`,
        color: `var(--chakra-colors-${TierMap[Tier.ARTEMIS].colorScheme}-800)`,
      }}
      borderRadius="md"
      cursor={cardType === CardType.APPLY ? 'pointer' : undefined}
      onClick={cardType === CardType.APPLY ? handleClick : undefined}
      px={5}
      py={3}
      userSelect="none"
    >
      <VStack alignItems="stretch" height="100%">
        <HStack>
          <Icon as={TierMap[Tier.ARTEMIS].icon} boxSize={6} />

          <Text flex={1} fontSize="xl" fontWeight="bold">
            {t('artemisButton.title')}
          </Text>

          <Text fontSize="xl" fontWeight="bold">
            {cardType === CardType.CURRENT ? t('active') : null}
            {cardType === CardType.APPLY ? t('apply') : null}
            {cardType === CardType.UNAVAILABLE ? t('unavailable') : null}
          </Text>
        </HStack>

        <Text fontSize="xs">
          {t('artemisButton.body')}
        </Text>

        <UnorderedList fontSize="xs">
          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('badge')}
              :
            </Text>
            {' '}
            {t('artemisButton.badge')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('baseScore')}
              :
            </Text>
            {' '}
            {t('artemisButton.baseScore')}
          </ListItem>

          <ListItem>
            <Text as="span" fontWeight="bold">
              {t('tripBudget')}
              :
            </Text>
            {' '}
            {t('artemisButton.tripBudget')}
          </ListItem>
        </UnorderedList>
      </VStack>
    </Box>
  );
}
