import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuGlobe2 } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';

export default function ExploreButton() {
  const { t } = useTranslation('MainLayout');

  const homeMatch = useMatch('/');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('exploreButton.default')}
        as={Link}
        className="exploreButton"
        icon={<Icon as={LuGlobe2} boxSize={6} />}
        isActive={!!homeMatch}
        isRound
        size="lg"
        to="/"
        variant="ghost"
      />
    </VStack>
  );
}
