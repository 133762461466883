import {
  Button,
  HStack,
  Image,
  Input,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import AppLanguage from '../../common/AppLanguage';
import DestinationAlgoliaSearchRecord from '../../common/DestinationAlgoliaSearchRecord';
import { getPhotoSizeUrl } from '../../common/getPhotoSizeUrl';
import { useAlgoliaSearchClient } from '../../components/AlgoliaSearchClientProvider';
import useGenerateDestination from '../../functions/useGenerateDestination';

export type Props = {
  onComplete: (res: {
    countryId: string;
    countryName: Record<AppLanguage, string>;
    description: Record<AppLanguage, string>;
    labels: string[];
    name: Record<AppLanguage, string>;
  }) => void;
};

export default function DestinationForm({ onComplete }: Props) {
  const { t } = useTranslation('DestinationCreateScreen', { keyPrefix: 'DestinationForm' });

  const client = useAlgoliaSearchClient();
  const destinationsIndex = useMemo(() => client.initIndex('destinations'), [client]);

  const schema = useMemo(
    () => yup.object().shape({
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      name: '',
    }),
    [],
  );

  const generateDestination = useGenerateDestination();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const { data } = await generateDestination({
        destinationName: values.name,
      });

      onComplete({
        countryId: data.countryISOKey,
        countryName: data.countryName,
        description: data.destinationDescription,
        labels: data.destinationLabels,
        name: data.destinationName,
      });
    },
    [generateDestination, onComplete],
  );

  const [query, setQuery] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [destinationsResult, setDestinationsResult] = useState<DestinationAlgoliaSearchRecord[]>();
  const [destinationsLoading, setDestinationsLoading] = useState<boolean>(true);

  useEffect(
    () => {
      setDestinationsLoading(true);
      destinationsIndex
        .search<DestinationAlgoliaSearchRecord>(
        query,
        {
          cacheable: false,
          hitsPerPage: 20,
          page,
        },
      )
        .finally(() => setDestinationsLoading(false))
        .then(
          ({ hits, nbPages }) => {
            setTotalPages(nbPages);
            setDestinationsResult(hits);
          },
        )
        .catch(() => { });
    },
    [destinationsIndex, page, query],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <Input
              flexShrink={0}
              onChange={(e) => {
                setQuery(e.target.value);
                setPage(0);
                setFieldValue('name', e.target.value).catch(() => { });
              }}
              value={values.name}
            />

            {destinationsResult?.length ? (
              <VStack alignItems="stretch">
                {destinationsResult.map(({
                  countryName, name, objectID, picture,
                }) => (
                  <HStack as={Link} key={objectID} to={`../${objectID}`}>
                    <Image
                      h={12}
                      src={getPhotoSizeUrl({
                        height: 48,
                        uri: picture.urls.raw,
                        width: 48,
                      })}
                      w={12}
                    />
                    <VStack alignItems="stretch" gap={0}>
                      <Text>
                        {name.en}
                      </Text>
                      <Text>{countryName.en}</Text>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            ) : (
              <Text>
                Nothing found
              </Text>
            )}

            <Wrap>
              {new Array(totalPages).fill(0).map((o, i) => (
                <Button
                  isActive={page === i}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => setPage(i)}
                >
                  {i}
                </Button>
              ))}
            </Wrap>
          </VStack>

          <Button
            isLoading={isSubmitting || destinationsLoading}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
