import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import { type ProfileDoc } from './Profiles';
import { type TripDoc } from './Trips';

export enum ApplicationStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
}

export enum ApplicationRejectedBy {
  ORGANIZER = 'ORGANIZER',
  SYSTEM = 'SYSTEM',
}

export interface ApplicationDoc {
  _v: 1;
  acceptedAt?: Timestamp;
  applicantRef: DocumentReference<ProfileDoc>;
  expiresAt: Timestamp;
  organizerRef: DocumentReference<ProfileDoc>;
  rejectedAt?: Timestamp;
  rejectedBy?: ApplicationRejectedBy;
  sentAt: Timestamp;
  status: ApplicationStatus;
  tripRef?: DocumentReference<TripDoc>;
}

export const isApplicationDoc = (
  data?: DocumentData,
): data is ApplicationDoc => true;

export const isApplicationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ApplicationDoc> => ref.parent.id === 'applications';

function isApplicationSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<ApplicationDoc>;
function isApplicationSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ApplicationDoc>;
function isApplicationSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<ApplicationDoc> | QueryDocumentSnapshot<ApplicationDoc> {
  return isApplicationRef(snap.ref);
}

export { isApplicationSnap };

export const getApplicationsCollectionRef = (firestore: Firestore) => collection(firestore, 'applications') as CollectionReference<ApplicationDoc>;

export const useApplicationsCollectionRef = () => {
  const firestore = useFirestore();
  return getApplicationsCollectionRef(firestore);
};
