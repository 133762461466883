import { HStack } from '@chakra-ui/react';
import { Suspense } from 'react';

import Catch from '../../../components/Catch';
import AphroditeButton from './AphroditeButton';
import ArtemisButton from './ArtemisButton';
import AthenaButton from './AthenaButton';
import DionysusButton from './DionysusButton';
import HeraButton from './HeraButton';
import HermesButton from './HermesButton';
import MortalButton from './MortalButton';
import PoseidonButton from './PoseidonButton';
import ZeusButton from './ZeusButton';

export function SubscriptionMain() {
  return (
    <HStack
      alignItems="stretch"
      gap={4}
      justifyContent="space-around"
      overflowX="auto"
      scrollSnapType="x mandatory"
      width="100%"
    >
      <MortalButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <HermesButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <DionysusButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <PoseidonButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <ZeusButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <ArtemisButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <AthenaButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <AphroditeButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
      <HeraButton
        flexShrink={0}
        scrollSnapAlign="center"
        scrollSnapStop="always"
        width="320px"
      />
    </HStack>
  );
}

export default function Subscription() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <SubscriptionMain />
      </Suspense>
    </Catch>
  );
}
