import TripBudget from './TripBudget';

/* eslint-disable perfectionist/sort-objects */
const TripBudgetMap: Record<TripBudget, { colorScheme: string; }> = {
  [TripBudget.ECONOMY]: { colorScheme: 'brown' },
  [TripBudget.STANDARD]: { colorScheme: 'cyan' },
  [TripBudget.PREMIUM]: { colorScheme: 'lightBlue' },
  [TripBudget.LUXURY]: { colorScheme: 'indigo' },
  [TripBudget.ULTIMATE]: { colorScheme: 'deepPurple' },
};
/* eslint-enable perfectionist/sort-objects */

export default TripBudgetMap;
