import {
  Badge,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart } from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { ApplicationStatus, useApplicationsCollectionRef } from '../../collections/Applications';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';

export function ApplicationsBadge() {
  const myProfileSnap = useMyProfileSnap();

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const { data: applicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('status', '==', ApplicationStatus.SENT),
      where('organizerRef', '==', myProfileSnap.ref),
      limit(10),
    ),
  );

  const applicationsCount = useMemo(
    () => applicationsSnap.docs.length,
    [applicationsSnap.docs.length],
  );

  if (!applicationsCount) {
    return null;
  }

  return (
    <Badge colorScheme="red" mr={-5} mt={-5} variant="solid" zIndex={10}>
      {applicationsCount > 9 ? '9+' : applicationsCount}
    </Badge>
  );
}

export default function Applications() {
  const { t } = useTranslation('MainLayout');

  const applicationsMatch = useMatch('/applications/*');

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('applicationsButton.default')}
        as={Link}
        className="applicationsButton"
        icon={<Icon as={LuHeart} boxSize={6} />}
        isActive={!!applicationsMatch}
        isRound
        size="lg"
        to="/applications"
        variant="ghost"
      />
      <Catch fallback={null}>
        <Suspense fallback={null}>
          <ApplicationsBadge />
        </Suspense>
      </Catch>
    </VStack>
  );
}
