import {
  AspectRatio,
  Box,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getPhotoSizeUrl } from '../../../common/getPhotoSizeUrl';
import Catch from '../../../components/Catch';
import { useTripSnap } from '../../../components/snapProviders/TripSnapProvider';
import useBlur from '../../../hooks/useBlur';
import TripDetails from './TripDetails';

export function TripMain() {
  const tripSnap = useTripSnap();
  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  const src = useMemo(
    () => getPhotoSizeUrl({
      height: 320,
      uri: tripDoc.picture.urls.raw,
      width: 180,
    }),
    [tripDoc.picture.urls.raw],
  );

  const fallbackSrc = useBlur({
    blurHash: tripDoc.picture.blur_hash,
    height: 32,
    width: 18,
  });

  return (
    <AspectRatio ratio={9 / 16}>
      <Box
        as={Link}
        borderRadius="md"
        boxShadow="base"
        h="100%"
        overflow="hidden"
        position="relative"
        to={`/plans/${tripSnap.id}`}
        w="100%"
      >
        <Image
          fallbackSrc={fallbackSrc}
          h="100%"
          objectFit="cover"
          position="absolute"
          src={src}
          w="100%"
        />

        <TripDetails
          _dark={{
            backgroundColor: 'rgb(from var(--chakra-colors-black) r g b / 0.5)',
          }}
          _light={{
            backgroundColor: 'rgb(from var(--chakra-colors-white) r g b / 0.5)',
          }}
          backdropFilter="saturate(180%) blur(20px)"
          bottom={0}
          left={0}
          position="absolute"
          right={0}
        />
      </Box>
    </AspectRatio>
  );
}

export function TripLoading() {
  return (
    <AspectRatio ratio={9 / 16}>
      <Skeleton h="100%" w="100%" />
    </AspectRatio>
  );
}

export default function Trip() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={<TripLoading />}>
        <TripMain />
      </Suspense>
    </Catch>
  );
}
