import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { ApplicationDoc } from '../../collections/Applications';

type Props = {
  applicationSnap: QueryDocumentSnapshot<ApplicationDoc>;
};

const ApplicationSnapContext = createContext<null | QueryDocumentSnapshot<ApplicationDoc>>(null);

export const useApplicationSnap = () => {
  const ctx = useContext(ApplicationSnapContext);

  if (!ctx) {
    throw new Error('ApplicationSnapProvider');
  }

  return ctx;
};

export default function ApplicationSnapProvider({
  applicationSnap,
  children,
}: PropsWithChildren<Props>) {
  return (
    <ApplicationSnapContext.Provider value={applicationSnap}>
      {children}
    </ApplicationSnapContext.Provider>
  );
}
