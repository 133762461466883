import { Button, VStack } from '@chakra-ui/react';
import { signInAnonymously } from 'firebase/auth';
import { doc, Timestamp, writeBatch } from 'firebase/firestore';
import { Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth, useFirestore } from 'reactfire';
import * as yup from 'yup';

import {
  getProfileHiddenRef,
  getProfilesCollectionRef,
  ProfileStatus,
} from '../../collections/Profiles';
import AppLanguage from '../../common/AppLanguage';
import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import Tier from '../../common/Tier';
import TripBudget from '../../common/TripBudget';
import Catch from '../../components/Catch';
import GenderFormControl from '../../components/GenderFormControl';
import useShowError from '../../hooks/useShowError';

export function GenderFormMain() {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'GenderForm' });

  const schema = useMemo(
    () => yup.object().shape({
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
    }),
    [t],
  );

  const [searchParams] = useSearchParams();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      if (
        searchParams.get('role')?.toLowerCase() === 'rich'
        || searchParams.get('gender')?.toLowerCase() === 'male'
      ) {
        return { gender: Gender.MALE };
      }

      return { gender: Gender.FEMALE };
    },
    [searchParams],
  );

  const firestore = useFirestore();
  const navigate = useNavigate();
  const auth = useAuth();
  const { i18n } = useTranslation();
  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async ({ gender }: typeof schema['__outputType']) => {
      try {
        const anonymous = await signInAnonymously(auth);

        mixpanel.identify(anonymous.user.uid);

        const myProfileRef = doc(getProfilesCollectionRef(firestore), anonymous.user.uid);
        const myProfileHiddenRef = getProfileHiddenRef(myProfileRef);

        const now = Date.now();
        const batch = writeBatch(firestore);

        batch.set(myProfileRef, {
          _v: 1,
          createdAt: Timestamp.fromMillis(now),
          expiresAt: Timestamp.fromMillis(now + 1000 * 60 * 60 * 24 * 365),
          gender,
          lastNotificationAt: Timestamp.fromMillis(now),
          lastSeenAt: Timestamp.fromMillis(now),
          score: 0,
          scoredAt: Timestamp.fromMillis(now),
          sexuality: Sexuality.STRAIGHT,
          status: ProfileStatus.UNPUBLISHED,
          tier: gender === Gender.FEMALE ? Tier.ARTEMIS : Tier.MORTAL,
          updatedAt: Timestamp.fromMillis(now),
        });

        batch.set(myProfileHiddenRef, {
          _v: 1,
          appLanguage: i18n.language as AppLanguage,
          maxTripBudget: gender === Gender.FEMALE ? TripBudget.STANDARD : TripBudget.ECONOMY,
        });

        await batch.commit();

        mixpanel.people.set({
          $created: now,
          appLanguage: i18n.language as AppLanguage,
          gender,
          lastSeenAt: now,
          maxTripBudget: gender === Gender.FEMALE ? TripBudget.STANDARD : TripBudget.ECONOMY,
          score: 0,
          sexuality: Sexuality.STRAIGHT,
          tier: gender === Gender.FEMALE ? Tier.ARTEMIS : Tier.MORTAL,
        });

        navigate('/');

        mixpanel.track('Gender Selected', { gender });
      } catch (error) {
        showError(error);
      }
    },
    [auth, firestore, i18n.language, navigate, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={0}
          gap={4}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <GenderFormControl
            label={t('gender.label')}
            name="gender"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('nextButton.loading')}
            type="submit"
            width="100%"
          >
            {t('nextButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

export default function GenderForm() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <GenderFormMain />
      </Suspense>
    </Catch>
  );
}
