import { Text, VStack } from '@chakra-ui/react';
import { logEvent, setUserId } from 'firebase/analytics';
import { RecaptchaVerifier, User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics, useAuth, useUser } from 'reactfire';

import { getProfileHiddenRef, useProfilesCollectionRef } from '../../../collections/Profiles';
import i18n from '../../../i18n';
import PhoneNumberForm, { PhoneNumberFormLoading } from './PhoneNumberForm';
import VerificationCodeForm from './VerificationCodeForm';

export default function Login() {
  const { t } = useTranslation('MainLayout', { keyPrefix: 'Onboarding.Login' });

  const { data: user } = useUser();

  if (!user) {
    throw new Error('Unauthenticated');
  }

  const analytics = useAnalytics();

  const auth = useAuth();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();

  useEffect(
    () => {
      const rv = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          theme: 'dark',
        },
      );
      setRecaptchaVerifier(rv);

      return () => {
        rv.clear();
      };
    },
    [auth],
  );

  const [
    phoneNumberFormResponse,
    setPhoneNumberFormResponse,
  ] = useState<{
    countryCode: string;
    phoneNumber: string;
    userExists: boolean;
    verificationId: string;
  }>();

  const onPhoneNumberFormComplete = useCallback(
    (value: {
      countryCode: string;
      phoneNumber: string;
      userExists: boolean;
      verificationId: string;
    }) => {
      setPhoneNumberFormResponse(value);
    },
    [],
  );

  const profilesCollectionRef = useProfilesCollectionRef();

  const onVerificationCodeFormComplete = useCallback(
    (values: { user: User }) => {
      setUserId(analytics, values.user.uid);

      getDoc(
        getProfileHiddenRef(
          doc(profilesCollectionRef, values.user.uid),
        ),
      )
        // eslint-disable-next-line consistent-return
        .then((profileHiddenSnap) => {
          const profileHiddenDoc = profileHiddenSnap.data();

          if (profileHiddenDoc?.appLanguage) {
            return i18n.changeLanguage(profileHiddenDoc.appLanguage);
          }
        })
        .catch(() => { });

      logEvent(analytics, 'login', {
        method: 'phone',
      });
    },
    [analytics, profilesCollectionRef],
  );

  const onVerificationCodeFormCancel = useCallback(
    () => {
      setPhoneNumberFormResponse(undefined);
    },
    [],
  );

  return (
    <VStack alignItems="stretch" gap={1}>
      <Text
        fontSize="xs"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {t('title')}
      </Text>

      {!recaptchaVerifier ? (
        <PhoneNumberFormLoading />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!phoneNumberFormResponse ? (
            <PhoneNumberForm
              onComplete={onPhoneNumberFormComplete}
              recaptchaVerifier={recaptchaVerifier}
            />
          ) : (
            <VerificationCodeForm
              onCancel={onVerificationCodeFormCancel}
              onComplete={onVerificationCodeFormComplete}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...phoneNumberFormResponse}
            />
          )}
        </>
      )}
    </VStack>
  );
}
