import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Icon,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  and,
  doc,
  limit,
  or,
  query,
  refEqual,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import mixpanel, { Dict } from 'mixpanel-browser';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuCheck, LuHeart, LuMessageSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire';

import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../../collections/Conversations';
import { isProfileComplete, ProfileStatus } from '../../../collections/Profiles';
import Gender from '../../../common/Gender';
import { useBannedAlert } from '../../../components/BannedAlertProvider';
import Catch from '../../../components/Catch';
import { useMyProfileSnap } from '../../../components/snapProviders/MyProfileSnapProvider';
import { useProfileSnap } from '../../../components/snapProviders/ProfileSnapProvider';
import useShowError from '../../../hooks/useShowError';

export function ActionCellMain() {
  const { t } = useTranslation('ProfileScreen', { keyPrefix: 'ActionCell' });

  const profileSnap = useProfileSnap();
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  if (!isProfileComplete(profileDoc)) {
    throw new Error('Profile is not complete');
  }

  const { data: originSnap } = useFirestoreDoc(profileDoc.originRef);

  if (!originSnap.exists()) {
    throw new Error('Origin does not exist');
  }

  const originDoc = useMemo(() => originSnap.data(), [originSnap]);

  const mixpanelData = useMemo<Dict>(() => ({
    age: profileDoc.age,
    createdAt: profileDoc.createdAt.toDate(),
    gender: profileDoc.gender,
    height: profileDoc.height,
    languages: profileDoc.languages,
    name: profileDoc.name,
    originCityId: profileDoc.originRef.id,
    originCountryId: originDoc.countryRef?.id,
    profileId: profileSnap.id,
    score: profileDoc.score,
    sexuality: profileDoc.sexuality,
    tier: profileDoc.tier,
    weight: profileDoc.weight,
    zodiac: profileDoc.zodiac,
  }), [
    originDoc.countryRef?.id,
    profileDoc.age,
    profileDoc.createdAt,
    profileDoc.gender,
    profileDoc.height,
    profileDoc.languages,
    profileDoc.name,
    profileDoc.originRef.id,
    profileDoc.score,
    profileDoc.sexuality,
    profileDoc.tier,
    profileDoc.weight,
    profileDoc.zodiac,
    profileSnap.id,
  ]);

  const showError = useShowError();
  const toast = useToast();

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const showBannedAlert = useBannedAlert();

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const { data: outgoingApplicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', profileSnap.ref),
      where('applicantRef', '==', myProfileSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      limit(1),
    ),
  );

  const outgoingApplicationSnap = useMemo(
    () => (outgoingApplicationsSnap.docs.length ? outgoingApplicationsSnap.docs[0] : undefined),
    [outgoingApplicationsSnap.docs],
  );

  const { data: incomingApplicationsSnap } = useFirestoreCollection(
    query(
      applicationsCollectionRef,
      where('organizerRef', '==', myProfileSnap.ref),
      where('applicantRef', '==', profileSnap.ref),
      where('status', '==', ApplicationStatus.SENT),
      limit(1),
    ),
  );

  const incomingApplicationSnap = useMemo(
    () => (incomingApplicationsSnap.docs.length ? incomingApplicationsSnap.docs[0] : undefined),
    [incomingApplicationsSnap.docs],
  );

  const conversationsCollectionRef = useConversationsCollectionRef();
  const { data: conversationsSnap } = useFirestoreCollection(
    query(
      conversationsCollectionRef,
      and(
        or(
          where('participantRefs', '==', [myProfileSnap.ref, profileSnap.ref]),
          where('participantRefs', '==', [profileSnap.ref, myProfileSnap.ref]),
        ),
        where('status', '==', ConversationStatus.OPENED),
      ),
      limit(1),
    ),
  );

  const conversationSnap = useMemo(
    () => (conversationsSnap.docs.length ? conversationsSnap.docs[0] : undefined),
    [conversationsSnap.docs],
  );

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleApplyClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Profile Apply Clicked', mixpanelData);

      if (myProfileDoc?.isBanned) {
        mixpanel.track('Profile Application Banned', mixpanelData);
        showBannedAlert();
        return;
      }

      setLoading(true);

      if (profileDoc.status !== ProfileStatus.PUBLISHED) {
        throw new Error('Profile should be published');
      }

      if (outgoingApplicationSnap) {
        throw new Error('Profile Join Request already exists');
      }

      const applicationRef = doc(applicationsCollectionRef);

      await setDoc(
        applicationRef,
        {
          _v: 1,
          applicantRef: myProfileSnap.ref,
          expiresAt: Timestamp.fromDate(moment.utc().add(1, 'year').toDate()),
          organizerRef: profileSnap.ref,
          sentAt: serverTimestamp(),
          status: ApplicationStatus.SENT,
        },
      );

      toast({
        isClosable: true,
        status: 'success',
        title: t('applyConfirmation'),
      });

      mixpanel.track('Profile Application Sent', mixpanelData);

      setLoading(false);
    },
    [
      applicationsCollectionRef,
      mixpanelData,
      myProfileDoc?.isBanned,
      myProfileSnap.ref,
      outgoingApplicationSnap,
      profileDoc.status,
      profileSnap.ref,
      showBannedAlert,
      t,
      toast,
    ],
  );

  const firestore = useFirestore();
  const navigate = useNavigate();

  const handleAcceptClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!incomingApplicationSnap) {
        throw new Error('Incoming Application not found');
      }

      e.stopPropagation();

      mixpanel.track('Profile Accept Clicked', mixpanelData);

      setLoading(true);

      const conversationRef = doc(conversationsCollectionRef);

      const batch = writeBatch(firestore);

      batch.set(
        conversationRef,
        {
          _v: 1,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          lastActionAt: Timestamp.now(),
          notReadByRefs: [],
          openedAt: Timestamp.now(),
          participantRefs: [myProfileSnap.ref, profileSnap.ref],
          readByRefs: [],
          status: ConversationStatus.OPENED,
          tripRefs: [],
          typingParticipantRefs: [],
        },
      );

      batch.set(
        incomingApplicationSnap.ref,
        {
          acceptedAt: Timestamp.now(),
          status: ApplicationStatus.ACCEPTED,
        },
        { merge: true },
      );

      batch.commit()
        .then(() => {
          setLoading(false);
          navigate(`/chats/${conversationRef.id}`);
        })
        .catch((err) => {
          setLoading(false);
          showError(err);
        });

      mixpanel.track('Application Accepted', {
        acceptedAt: new Date(),
        applicantId: incomingApplicationSnap.data().applicantRef.id,
        applicationId: incomingApplicationSnap.id,
        organizerId: incomingApplicationSnap.data().organizerRef.id,
        sentAt: incomingApplicationSnap.data().sentAt.toDate(),
        status: ApplicationStatus.ACCEPTED,
      });
    },
    [
      conversationsCollectionRef,
      firestore,
      incomingApplicationSnap,
      mixpanelData,
      myProfileSnap.ref,
      navigate,
      profileSnap.ref,
      showError,
    ],
  );

  const handleMessageClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!conversationSnap) {
        throw new Error('Conversation not found');
      }

      mixpanel.track('Profile Conversation Clicked', mixpanelData);

      navigate(`/chats/${conversationSnap.id}`);
    },
    [
      conversationSnap,
      mixpanelData,
      navigate,
    ],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (myProfileDoc && isProfileComplete(myProfileDoc)) {
    if (refEqual(myProfileSnap.ref, profileSnap.ref)) {
      return (
        <IconButton
          aria-label={t('sendMessageButton.default')}
          className="profileAction"
          icon={<Icon as={LuMessageSquare} />}
          isDisabled
          size="lg"
          variant="solid"
        />
      );
    }

    if (conversationSnap) {
      return (
        <IconButton
          aria-label={t('sendMessageButton.default')}
          className="profileAction"
          icon={<Icon as={LuMessageSquare} />}
          onClick={handleMessageClick}
          size="lg"
          variant="solid"
        />
      );
    }

    if (incomingApplicationSnap) {
      return (
        <IconButton
          aria-label={t('acceptButton.default')}
          className="profileAction"
          colorScheme="green"
          icon={<Icon as={LuCheck} />}
          isLoading={isLoading}
          onClick={handleAcceptClick}
          size="lg"
          variant="solid"
        />
      );
    }

    if (outgoingApplicationSnap) {
      return (
        <IconButton
          aria-label={t('applyButton.disabled')}
          className="profileAction"
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          isDisabled
          size="lg"
          variant="solid"
        />
      );
    }

    // Only men sending applications fo girls is allowed
    if (profileDoc.gender !== Gender.FEMALE || myProfileDoc.gender !== Gender.MALE) {
      return (
        <IconButton
          aria-label={t('applyButton.default')}
          className="profileAction"
          colorScheme="pink"
          icon={<Icon as={LuHeart} />}
          isDisabled
          size="lg"
          variant="solid"
        />
      );
    }

    return (
      <IconButton
        aria-label={t('applyButton.default')}
        className="profileAction"
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        isLoading={isLoading}
        onClick={(e) => { handleApplyClick(e).catch(showError); }}
        size="lg"
        variant="solid"
      />
    );
  }

  return (
    <>
      <IconButton
        aria-label={t('applyButton.default')}
        className="profileAction"
        colorScheme="pink"
        icon={<Icon as={LuHeart} />}
        onClick={(e) => { e.stopPropagation(); onOpen(); }}
        size="lg"
        variant="solid"
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogContent
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('applyAlertModal.title')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('applyAlertModal.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} variant="ghost">
              {t('applyAlertModal.cancelButton.default')}
            </Button>

            <Button as={Link} ml={3} to="/profile/update">
              {t('applyAlertModal.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default function ActionCell() {
  return (
    <Catch fallback={null}>
      <Suspense
        fallback={(
          <IconButton
            aria-label="Loading"
            className="profileAction"
            colorScheme="pink"
            icon={<Icon as={LuHeart} />}
            isLoading
            size="lg"
            variant="solid"
          />
        )}
      >
        <ActionCellMain />
      </Suspense>
    </Catch>
  );
}
