import {
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Skeleton,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuArrowRight } from 'react-icons/lu';
import { useAuth } from 'reactfire';
import * as yup from 'yup';

import countryCodes from '../../../common/countryCodes';
import Catch from '../../../components/Catch';
import { useIPData } from '../../../components/IPDataProvider';
import useUserWithPhoneNumberExists from '../../../functions/useUserWithPhoneNumberExists';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  countryCode?: string;
  onComplete: (values: {
    countryCode: string,
    phoneNumber: string,
    userExists: boolean,
    verificationId: string,
  }) => void;
  // eslint-disable-next-line react/require-default-props
  phoneNumber?: string;
  recaptchaVerifier: RecaptchaVerifier;
};

export function PhoneNumberFormMain({
  countryCode,
  onComplete,
  phoneNumber,
  recaptchaVerifier,
}: Props) {
  const { t } = useTranslation('MainLayout', { keyPrefix: 'Onboarding.Login.PhoneNumberForm' });

  const schema = useMemo(
    () => yup.object().shape({
      countryCode: yup
        .string()
        .label(t('countryCode.label'))
        .required(),
      phoneNumber: yup
        .string()
        .label(t('phoneNumber.label'))
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      countryCode: countryCode ?? ipData?.calling_code ?? '',
      phoneNumber: phoneNumber ?? '',
    }),
    [countryCode, ipData?.calling_code, phoneNumber],
  );

  const auth = useAuth();

  const phoneAuthProvider = useMemo(() => new PhoneAuthProvider(auth), [auth]);

  const userWithPhoneNumberExists = useUserWithPhoneNumberExists();
  const onSubmit = useCallback(
    async (
      values: typeof schema['__outputType'],
      formikHelpers: FormikHelpers<typeof schema['__outputType']>,
    ) => {
      if (!recaptchaVerifier) {
        return;
      }

      try {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          `+${values.countryCode}${values.phoneNumber}`,
          recaptchaVerifier,
        );

        const { data: { exists } } = await userWithPhoneNumberExists({
          phoneNumber: `+${values.countryCode}${values.phoneNumber}`,
        });

        mixpanel.track('Onboarding Phone Number Step Completed', {
          ...values,
          userExists: exists,
        });

        onComplete({
          ...values,
          userExists: exists,
          verificationId,
        });
      } catch (err) {
        if (err instanceof FirebaseError) {
          if (err.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
            formikHelpers.setFieldError('phoneNumber', 'Too many attempts, try later.');
            return;
          }

          if (err.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
            formikHelpers.setFieldError('phoneNumber', 'Invalid phone number.');
            return;
          }

          formikHelpers.setFieldError('phoneNumber', err.message);
          return;
        }

        throw err;
      }
    },
    [onComplete, phoneAuthProvider, recaptchaVerifier, userWithPhoneNumberExists],
  );

  const codes = useMemo(
    () => _.uniq(countryCodes.map(({ calling_code }) => calling_code)).sort(),
    [],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik<typeof schema['__outputType']>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <HStack
          as="form"
          gap={2}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FormControl flex={1} isInvalid={!!errors.phoneNumber || !!errors.countryCode}>
            <HStack>
              <Select
                autoComplete="tel-country-code"
                onChange={handleChange('countryCode')}
                size="sm"
                value={values.countryCode}
                w="120px"
              >
                {codes.map((calling_code) => (
                  <option key={calling_code} value={calling_code}>
                    +
                    {calling_code}
                  </option>
                ))}
              </Select>

              <Input
                autoComplete="tel-national"
                autoFocus
                inputMode="tel"
                onChange={handleChange('phoneNumber')}
                placeholder={t('phoneNumber.label')}
                size="sm"
                type="tel"
                value={values.phoneNumber}
              />
            </HStack>
          </FormControl>

          <IconButton
            aria-label={t('sendCodeButton.default')}
            flex={0}
            icon={<Icon as={LuArrowRight} />}
            isLoading={isSubmitting}
            size="sm"
            type="submit"
          />
        </HStack>
      )}
    </Formik>
  );
}

export function PhoneNumberFormLoading() {
  return (
    <HStack>
      <Skeleton h={8} w={24} />
      <Skeleton flex={1} h={8} />
      <Skeleton h={8} w={8} />
    </HStack>
  );
}

export default function PhoneNumberForm(props: Props) {
  return (
    <Catch fallback={<PhoneNumberFormLoading />}>
      <Suspense fallback={<PhoneNumberFormLoading />}>
        <PhoneNumberFormMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
