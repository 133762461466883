import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import {
  doc,
  serverTimestamp,
  setDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuCheck, LuX } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { useFirestore } from 'reactfire';

import { ApplicationStatus } from '../../../collections/Applications';
import {
  ConversationStatus,
  useConversationsCollectionRef,
} from '../../../collections/Conversations';
import Catch from '../../../components/Catch';
import { useApplicationSnap } from '../../../components/snapProviders/ApplicationSnapProvider';
import useShowError from '../../../hooks/useShowError';

export function InfoCellMain() {
  const applicationSnap = useApplicationSnap();

  const { t } = useTranslation('ApplicationsScreen', { keyPrefix: 'Application' });

  const showError = useShowError();

  const applicationDoc = useMemo(() => applicationSnap.data(), [applicationSnap]);

  const navigate = useNavigate();
  const firestore = useFirestore();
  const conversationsCollectionRef = useConversationsCollectionRef();
  const [accepting, setAccepting] = useState<boolean>(false);
  const handleAcceptClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setAccepting(true);

      const conversationRef = doc(conversationsCollectionRef);

      const batch = writeBatch(firestore);

      batch.set(
        conversationRef,
        {
          _v: 1,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          lastActionAt: serverTimestamp(),
          notReadByRefs: [],
          openedAt: serverTimestamp(),
          participantRefs: [applicationDoc.organizerRef, applicationDoc.applicantRef],
          readByRefs: [],
          status: ConversationStatus.OPENED,
          tripRefs: applicationDoc.tripRef ? [applicationDoc.tripRef] : [],
          typingParticipantRefs: [],
        },
      );

      batch.set(
        applicationSnap.ref,
        {
          acceptedAt: serverTimestamp(),
          status: ApplicationStatus.ACCEPTED,
        },
        { merge: true },
      );

      batch.commit()
        .then(() => {
          setAccepting(false);
          navigate(`/chats/${conversationRef.id}`);
        })
        .catch((err) => {
          setAccepting(false);
          showError(err);
        });

      mixpanel.track('Application Accepted', {
        acceptedAt: applicationDoc.acceptedAt?.toDate(),
        applicantId: applicationDoc.applicantRef.id,
        applicationId: applicationSnap.id,
        organizerId: applicationDoc.organizerRef.id,
        rejectedAt: applicationDoc.rejectedAt?.toDate(),
        rejectedBy: applicationDoc.rejectedBy,
        sentAt: applicationDoc.sentAt.toDate(),
        status: applicationDoc.status,
        tripId: applicationDoc.tripRef?.id,
      });
    },
    [
      applicationDoc,
      applicationSnap.id,
      applicationSnap.ref,
      conversationsCollectionRef,
      firestore,
      navigate,
      showError,
    ],
  );

  const handleRejectConfirmClick = useCallback(
    () => {
      setDoc(
        applicationSnap.ref,
        {
          rejectedAt: serverTimestamp(),
          status: ApplicationStatus.REJECTED,
        },
        { merge: true },
      )
        .catch(showError);

      mixpanel.track('Application Rejected', {
        acceptedAt: applicationDoc.acceptedAt?.toDate(),
        applicantId: applicationDoc.applicantRef.id,
        applicationId: applicationSnap.id,
        organizerId: applicationDoc.organizerRef.id,
        rejectedAt: applicationDoc.rejectedAt?.toDate(),
        rejectedBy: applicationDoc.rejectedBy,
        sentAt: applicationDoc.sentAt.toDate(),
        status: applicationDoc.status,
        tripId: applicationDoc.tripRef?.id,
      });
    },
    [
      applicationDoc,
      applicationSnap.id,
      applicationSnap.ref,
      showError,
    ],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleRejectClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onOpen();
    },
    [onOpen],
  );

  return (
    <>
      <IconButton
        aria-label={t('rejectButton.default')}
        icon={<Icon as={LuX} />}
        onClick={handleRejectClick}
        size="lg"
        variant="solid"
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogContent
          mx={4}
        >
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('rejectAlertModal.title')}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('rejectAlertModal.body')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} variant="ghost">
              {t('rejectAlertModal.cancelButton.default')}
            </Button>

            <Button as={Link} ml={3} onClick={handleRejectConfirmClick}>
              {t('rejectAlertModal.confirmButton.default')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <IconButton
        aria-label={t('acceptButton.default')}
        colorScheme="green"
        icon={<Icon as={LuCheck} />}
        isLoading={accepting}
        onClick={handleAcceptClick}
        size="lg"
        variant="solid"
      />
    </>
  );
}

export default function InfoCell() {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <InfoCellMain />
      </Suspense>
    </Catch>
  );
}
