import { Button, Container, VStack } from '@chakra-ui/react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import {
  deleteField,
  doc,
  getDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAnalytics, useFirestore } from 'reactfire';
import * as yup from 'yup';

import { useCitiesCollectionRef } from '../../collections/Cities';
import { ProfileStatus } from '../../collections/Profiles';
import dateToZodiac from '../../common/dateToZodiac';
import Gender from '../../common/Gender';
import Language from '../../common/Language';
import Sexuality from '../../common/Sexuality';
import { StoragePicture } from '../../common/StoragePicture';
import CityFormControl from '../../components/CityFormControl';
import GenderFormControl from '../../components/GenderFormControl';
import InstagramFormControl from '../../components/InstagramFormControl';
import { useIPData } from '../../components/IPDataProvider';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import LinkedinFormControl from '../../components/LinkedinFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import SexualityFormControl from '../../components/SexualityFormControl';
import {
  useMyProfileHiddenSnap,
} from '../../components/snapProviders/MyProfileHiddenSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextFormControl from '../../components/TextFormControl';
import TiktokFormControl from '../../components/TiktokFormControl';

export default function ProfileForm() {
  const { t } = useTranslation('MyProfileUpdateScreen', { keyPrefix: 'ProfileForm' });

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .required(),
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label'))
        .optional(),
      languages: yup
        .array(yup
          .string()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
      linkedinTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('linkedinTag.matchesError'))
        .label(t('linkedinTag.label'))
        .optional(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
      pictures: yup
        .array()
        .label(t('pictures.label'))
        .compact()
        .min(1)
        .max(6)
        .required()
        .of(yup
          .object()
          .label(t('pictures.item.label'))
          .required()
          .shape({
            blurHash: yup.string(),
            imgixUrl: yup.string().required(),
            storageRef: yup.string().required(),
          })),
      sexuality: yup
        .string()
        .label(t('sexuality.label'))
        .oneOf(Object.values(Sexuality))
        .required(),
      tiktokTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('tiktokTag.matchesError'))
        .label(t('tiktokTag.label'))
        .optional(),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = _.uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        birthDate: myProfileHiddenDoc?.birthDate ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? moment().subtract(20, 'years').format('YYYY-MM-DD')
            : moment().subtract(35, 'years').format('YYYY-MM-DD')
        ),
        gender: myProfileDoc?.gender ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? Gender.FEMALE
            : Gender.MALE
        ),
        height: myProfileDoc?.height ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? 170
            : 180
        ),
        instagramTag: myProfileDoc?.instagramTag ?? '',
        languages: myProfileDoc?.languages ?? languages,
        linkedinTag: myProfileDoc?.linkedinTag ?? '',
        name: myProfileDoc?.name ?? '',
        originId: myProfileDoc.originRef?.id ?? '',
        pictures: myProfileDoc.pictures ?? [],
        sexuality: myProfileDoc.sexuality ?? Sexuality.STRAIGHT,
        tiktokTag: myProfileDoc?.tiktokTag ?? '',
        weight: myProfileDoc.weight ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? 50
            : 80
        ),
      };
    },
    [
      ipData,
      myProfileDoc,
      myProfileHiddenDoc,
    ],
  );

  const firestore = useFirestore();
  const citiesCollectionRef = useCitiesCollectionRef();

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const originRef = doc(citiesCollectionRef, values.originId);
      const originSnap = await getDoc(originRef);
      const originDoc = originSnap.data();

      if (!originSnap.exists() || !originDoc) {
        throw new Error('City not found');
      }

      const birthDate = moment.utc(values.birthDate).startOf('day');

      const age = Math.floor(
        moment.duration(
          moment().diff(birthDate),
        ).asYears(),
      );

      const zodiac = dateToZodiac(birthDate.toDate());

      const pictures: StoragePicture[] = _.compact(values.pictures);

      const batch = writeBatch(firestore);

      batch.set(
        myProfileSnap.ref,
        {
          age,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          gender: values.gender,
          height: values.height,
          instagramTag: typeof values.instagramTag === 'string' && values.instagramTag !== '' ? values.instagramTag : deleteField(),
          languages: values.languages,
          linkedinTag: typeof values.linkedinTag === 'string' && values.linkedinTag !== '' ? values.linkedinTag : deleteField(),
          name: values.name,
          originRef,
          pictures,
          sexuality: values.sexuality,
          status: ProfileStatus.PUBLISHED,
          tiktokTag: typeof values.tiktokTag === 'string' && values.tiktokTag !== '' ? values.tiktokTag : deleteField(),
          updatedAt: Timestamp.now(),
          weight: values.weight,
          zodiac,
        },
        { merge: true },
      );

      batch.set(
        myProfileHiddenSnap.ref,
        {
          birthDate: birthDate.format('YYYY-MM-DD'),
          timezone: originDoc.timezone,
        },
        { merge: true },
      );

      await batch.commit();

      mixpanel.people.set({
        $avatar: pictures[0].imgixUrl,
        $name: values.name,
        age,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      mixpanel.track('My Profile Updated', {
        age,
        avatar: pictures[0].imgixUrl,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        name: values.name,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      logEvent(analytics, 'update_profile');
      setUserProperties(analytics, {
        age,
        languages: values.languages,
        sexuality: values.sexuality,
        zodiac,
      });

      navigate('..');
    },
    [
      analytics,
      citiesCollectionRef,
      firestore,
      navigate,
      myProfileHiddenSnap.ref,
      myProfileSnap.ref,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <VStack alignItems="stretch" flex={1} gap={0} minH={0}>
          <Container
            flex={1}
            maxW="lg"
            overflowY="auto"
            py={2}
          >
            <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
              <PicturesFormControl
                label={t('pictures.label')}
                name="pictures"
              />

              <TextFormControl
                autoComplete="given-name"
                label={t('name.label')}
                name="name"
                type="text"
              />

              <CityFormControl
                label={t('originId.label')}
                name="originId"
                placeholder={t('originId.placeholder')}
              />

              <GenderFormControl
                label={t('gender.label')}
                name="gender"
              />

              <SexualityFormControl
                gender={values.gender}
                label={t('sexuality.label')}
                name="sexuality"
              />

              <TextFormControl
                autoComplete="bday"
                label={t('birthDate.label')}
                name="birthDate"
                type="date"
              />

              <NumberFormControl
                label={t('height.label')}
                max={250}
                min={150}
                name="height"
                right={t('height.unit')}
                step={1}
              />

              <NumberFormControl
                label={t('weight.label')}
                max={120}
                min={45}
                name="weight"
                right={t('weight.unit')}
                step={1}
              />

              <LanguagesFormControl
                label={t('languages.label')}
                name="languages"
                placeholder={t('languages.placeholder')}
              />

              <InstagramFormControl
                label={t('instagramTag.label')}
                name="instagramTag"
                type="text"
              />

              <TiktokFormControl
                label={t('tiktokTag.label')}
                name="tiktokTag"
                type="text"
              />

              <LinkedinFormControl
                label={t('linkedinTag.label')}
                name="linkedinTag"
                type="text"
              />
            </VStack>
          </Container>

          <Container
            maxW="lg"
            py={2}
          >
            <Button
              isLoading={isSubmitting}
              loadingText={t('updateProfileButton.loading')}
              onClick={() => handleSubmit()}
              w="100%"
            >
              {t('updateProfileButton.default')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}
